import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

class ForbiddenPage extends Component {
	handleClick = (e) => {
		e.preventDefault()
	}

	render() {
		return (
			<div style={{textAlign: "center"}}>
				<h1 style={{color: "white"}}>403 Forbidden</h1>
				<Button type="primary" htmlType="submit">
					<Link to='/'>Home Page</Link>
				</Button>
			</div>
		)
				
	}
}

export default ForbiddenPage
