import React from 'react'
import { withTranslation } from 'react-i18next'
import { Field, FieldArray } from 'redux-form'
import { forEach, filter, map } from 'lodash'
import { Tabs, Modal, Form, Row, Col } from 'antd'
import { createItem } from '../../atoms/formItems/AItem'
import UploadImage from '../../atoms/UploadImage'
import UploadFile from '../../atoms/UploadFile'
import Materials from './Materials'
import CreateVariantForm from './CreateVariantForm'

const { TabPane } = Tabs

const AUpload = createItem(UploadImage)
const AUploadFile = createItem(UploadFile)

const WrappedCreateVariantForm = Form.create({ name: 'createVariant' })(CreateVariantForm)

class Variants extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			activeVariantTabKey: '0',
			variantModalVisible: false,
			showRemoveConfirmModal: null
		}
	}

	onChangeVariant = (activeKey) => {
		this.setState({ activeVariantTabKey: activeKey })
	}
	
	onEditVariant = (targetKey, action) => {

		if (action === 'add') {
			this.setState({
				variantModalVisible: true
			})
		} else if (action === 'remove') {
			this.setState({ showRemoveConfirmModal: targetKey })
		}
	}

	handleModalCancel = () => {
		const { form } = this.formRef.props

		form.resetFields()

		this.setState({
			variantModalVisible: false
		})
	}

	handleModalOk = () => {
		const { fields, materials } = this.props
		const { form } = this.formRef.props
		
		form.validateFields((err, values) => {
			if (err) {
				return
			}

			const { variantName } = values
			form.resetFields()		

			const newVariantKey = fields.length

			const initMaterials = map(materials, (material) => {
				return { name: material}
			})

			fields.push({
				name: variantName,
				materials: initMaterials
			})
			this.setState({
				activeVariantTabKey: `${newVariantKey}`,
				variantModalVisible: false
			})
		})
	}

	removeVariant = (targetKey) => {
		const { fields } = this.props
		const { activeVariantTabKey } = this.state
		let lastIndex

		forEach(fields.getAll(), (variant, i) => {
			if (`${i}` === targetKey) {
				lastIndex = i - 1
			}
		})

		const newVariants = filter(fields.getAll(), variant => variant.key !== targetKey)

		fields.remove(lastIndex + 1)

		if (newVariants.length && `${activeVariantTabKey}` === targetKey) {
			this.setState({
				activeVariantTabKey: lastIndex
			})
		}
		this.setState({ showRemoveConfirmModal: null })
	}

	saveFormRef = formRef => {
		this.formRef = formRef
	}

	render = () => {
		const { fields, materials, t } = this.props
		const { activeVariantTabKey, variantModalVisible, showRemoveConfirmModal } = this.state
		
		return (
			<Row>
				<Col offset={2} span={20}>
					<Tabs type="editable-card" onChange={this.onChangeVariant} activeKey={`${activeVariantTabKey}`} onEdit={this.onEditVariant}>
						{
							fields.map((variant, index) => {
								const variantValues = fields.get(index)
								const { name } = variantValues
								const regex = new RegExp(/shared/, 'i')
								const isShared = variantValues.default || regex.test(variantValues.name)
								const closable = variantValues.closable || !isShared
								
								return (
									<TabPane tab={name} key={index} closable={closable}>
										{
											(!isShared) && <Field
												labelCol={{ span: 2 }}
												wrapperCol={{ span: 20 }}
												name={`${variant}.thumbnail`}
												className="avatar-uploader"
												label="Thumbnail"
												width={250}
												height={250}
												showCropper={true}
												component={AUpload}
											/>
										}

										{
											(!isShared) && <Field
												uploadAsZip
												labelCol={{ span: 2 }}
												wrapperCol={{ span: 20 }}
												name={`${variant}.modelObj`}
												label={t('components:3DModelOBJ')}
												placeholder={t('components:3DModelOBJ')}
												getData={this.handleModelObjData}
												component={AUploadFile} />
										}

										<FieldArray name={`${variant}.materials`} component={Materials} materials={materials} />
									</TabPane>
								)
							})
						}
					</Tabs>	

					<Modal visible={variantModalVisible} title="Create a new variant" okText="Create"
						onCancel={this.handleModalCancel} onOk={this.handleModalOk} >
						<WrappedCreateVariantForm wrappedComponentRef={this.saveFormRef} t={t}/>
					</Modal>
					<Modal
						visible={!!showRemoveConfirmModal}
						
						title="Remove variant"
						okText="Remove"
						onCancel={() => this.setState({ showRemoveConfirmModal: null })}
						onOk={() => this.removeVariant(showRemoveConfirmModal)}
					>
						<p>Are you sure to remove a variant ?</p>
					</Modal>
				</Col>
			</Row>
		)
	}
}

export default withTranslation('components')(Variants)
