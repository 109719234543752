import React, { Component } from 'react'

// const PrivateRoute = lazy(() => import('./atoms/PrivateRoute'))
import Routers from './routes/Routes'

class App extends Component {
	render() {
		return (
			<div className="App">
				<Routers/>
			</div>
		)
	}
}

export default App
