import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Form, Input, Select, Button, Tabs } from 'antd'

import UploadImage from '../../atoms/UploadImage'
import { createItem } from '../../atoms/formItems/AItem'
import { createSelect } from '../../atoms/formItems/ASelect'
import  { required } from '../../enums/formValidations'
import { FORMS, LANGUAGES } from '../../enums/enums'

const FormItem = Form.Item
const { Option } = Select
const { TabPane } = Tabs

const AInput = createItem(Input)
const ASelect = createSelect(Select)
const AUpload = createItem(UploadImage)

class CategoryForm extends React.Component {
	handleSwitchChange = (value) => {
		this.setState({
			urlOrBrand: value
		})
	}

	onChangeLanguage = (language) => {
		const { change, initForm, pristine, submit } = this.props
		if (pristine && initForm) {
			initForm(language)
		} else if (pristine) {
			change('language', language)
		} else if (!pristine && initForm){
			submit()
			initForm(language)
		} else {
			submit()
			change('language', language)
		}
	}

	render = () => {
		const { applications, submitText, language, t, handleSubmit, pristine, reset, submitting, initialized } = this.props

		const applicationOptions = applications && map(applications, (application) => {
			const { id, name } = application
			return <Option value={name} key={id}>{name}</Option>
		})

		const requiredField = { required: true, validate: [required] }
				
		return (<Tabs onChange={this.onChangeLanguage} activeKey={language}>
			{LANGUAGES.map(pane => (
				<TabPane tab={pane} key={pane}>
					<Form onSubmit={handleSubmit} >
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="name" label={t('components:name')} placeholder={t('components:name')} {...requiredField} component={AInput} />
			
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="image" className="avatar-uploader" label="Image" {...requiredField}
							width={250} height={250} showCropper={true} initialized={initialized} component={AUpload} />
				
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="applications" label={t('components:applications')} placeholder={t('components:applicationsPlaceholder')}
							{...requiredField} mode="multiple" component={ASelect} >
							{applicationOptions}
						</Field>
						
						<FormItem>
							<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
								{t(`components:${submitText}`)}
							</Button>

							<Button disabled={pristine || submitting} onClick={reset}>
							{t('components:resetValues')}
							</Button>
						</FormItem>
					</Form>
			</TabPane>))}
		</Tabs>)
	}
}

const selector = formValueSelector(FORMS.CATEGORY)

const mapStateToProps = (state) => {
	return {
		language: selector(state, 'language'),
		applications: state.application.list.data
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		
	}
}

const form = reduxForm({
	form: FORMS.CATEGORY,
	destroyOnUnmount: true
})(CategoryForm)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation('components')
)(form)
