import React from 'react'
import { Form, Input } from 'antd'

class CreateVariantForm extends React.Component {

	render = () => {
		const { t, form } = this.props
		const { getFieldDecorator } = form

		return (
			<Form>
				<Form.Item>
					{getFieldDecorator('variantName', {
						rules: [
							{
								required: true,
								message: t('components:variantNameIsRequired'),
							}
						],
					})(
						<Input
							name="variantName"
							placeholder={t('components:variantNamePlaceholder')}
							onChange={this.handleInputChange}
						/>
					)}
				</Form.Item>
			</Form>
		)
	}
}

export default CreateVariantForm
