import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { Form, Input, Button } from 'antd'
import { createItem } from '../../atoms/formItems/AItem'
import { required, email } from '../../enums/formValidations'
import { resetForgotPasswordForm } from '../../actions/authActions'

const FormItem = Form.Item

const AInput = createItem(Input)

class ForgotPasswordForm extends React.Component {
	componentDidMount = () => {
		const { resetForgotPasswordForm } = this.props
		resetForgotPasswordForm()
	}
	
	render = () => {
		const { handleSubmit, pristine, submitting } = this.props
		const { t } = this.props
		
		return (
			<Form onSubmit={handleSubmit} className="form-border">
				<div style={{textAlign: 'center'}}>
					<h2 style={{color: 'white'}}>{t('components:forgotPasswordTitle')}</h2>
					<p style={{color: 'white'}}>{t('components:forgotPasswordContent')}</p>
				</div>

				<Field name="email" placeholder={t('components:emailPlaceholder')} component={AInput} 
					required={true} validate={[required, email]} wrapperCol={{span: 24}} />

				<FormItem wrapperCol={{span: 24}}>
					<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
						{t(`components:resetPassword`)}
					</Button>
				</FormItem>
			</Form>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetForgotPasswordForm: () => {
			dispatch(resetForgotPasswordForm())
		}
	}
}

export default compose(
	connect(null, mapDispatchToProps),
	withTranslation('components')
)(ForgotPasswordForm)
