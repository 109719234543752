import React from 'react'
import { FieldArray } from 'redux-form'
import { Card} from 'antd'
import Textures from './Textures' 

class Materials extends React.Component {

	render = () => {
		const { fields } = this.props
		
		return (
			fields.map((material, index) => {
				const materialValues = fields.get(index)
				const { name } = materialValues

				return (
					<Card title={name} key={name}>
						<FieldArray name={`${material}.textures`} component={Textures} />
					</Card>
				)
			})
		)
	}
}

export default Materials
