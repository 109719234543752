import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

class PrivateRoute extends Route {
	static propTypes = {
		component: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.object
		]).isRequired,
		layout: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.object
		]).isRequired,
		t: PropTypes.func.isRequired
	}

	render = () => {
		const { isLoggedIn } = this.props

		if (!isLoggedIn) {
			// if (window.location.pathname !== '/') {
			// 	localStorage.setItem('initUrl', window.location.pathname)
			// }
			return <Redirect to="/login" />
		}

		if (this.props.layout) {
			return (
				<this.props.layout {...this.props}>
					<this.props.component {...this.props} />
				</this.props.layout>
			)
		}

		return (<this.props.component {...this.props} />)
	}
}
const mapStateToProps = (state) => {
	return {
		isLoggedIn: state.auth.isLoggedIn
	}
}

export default compose(
	withTranslation('components'),
	connect(mapStateToProps)
)(PrivateRoute)

