import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'
import { initializeInstances } from './utils/axiosWrapper'
import './utils/i18n'

import "antd/dist/antd.css"
import './index.css'

import App from './App'

const persistConfig = {
	key: 'root',
	storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
let store 
if(process.env.NODE_ENV === 'production') {
	store = createStore(persistedReducer, applyMiddleware(thunk))
} else {
	store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
}
const persistor = persistStore(store)

initializeInstances(store)

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>, 
	document.getElementById('root'))
