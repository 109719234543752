import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, message } from 'antd'
import { map, isArray, get } from 'lodash'
import { compose, bindActionCreators } from 'redux'
import { initialize } from 'redux-form'

import BrandForm from './BrandForm'
import * as BrandActions from '../../actions/brandActions'
import { withPermissions } from '../../utils/permissionsHoc'
import { ROLE_TYPE, FORMS, LANGUAGE } from '../../enums/enums'
import { getIdsFromNames, convertNullToUndefined } from '../../utils/helper'
import { backendAxios } from '../../utils/axiosWrapper'
import errorHandler from '../../utils/errorHandler'

class UpdateBrand extends Component {

	initForm = async (language) => {
		const { computedMatch, brandActios, dispatch } = this.props
		const { brandID } = computedMatch.params
		
		const brand = await brandActios.getBrand(brandID, language)

		const assignedApps = map(brand.applications, 'name')

		const initData = { ...brand, applications: assignedApps, language }
		dispatch(initialize(FORMS.BRAND, initData))
	}
	componentDidMount = async () => {
		this.initForm(LANGUAGE.SK)
	}

	handleSubmit = async (values) => {
		const { computedMatch, allApplications, dispatch } = this.props
		const { brandID } = computedMatch.params
		// brandActios.updateBrand(brandID, values)
		const hideLoading = message.loading('Updating in progress..', 0)
		try {
			
			// modified data for request (null->undefined)
			const payload = convertNullToUndefined(values)
			const { logo, applications } = payload

			// get applications ids
			// const allApplications = getState().application.list.data
			const applicationIds = getIdsFromNames(applications, allApplications)

			let resultImage
			if(isArray(logo)){
				resultImage = logo && logo[0].url
			} else {
				resultImage = logo
			}

			// final data for request
			const brandData = {
				name: payload.name,
				description: payload.description,
				isPromoted: payload.isPromoted,
				logo: resultImage,
				applicationIds,
				language: values.language
			}
			
			const { data } = await backendAxios.put(`/brands/${brandID}`, brandData)
			
			hideLoading()
			message.success(`Brand with ID: ${get(data, 'id')} was updated`, 3)
			return data
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Updating')
			return err
		}
	}

	render() {
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Update brand</h1>
					<BrandForm onSubmit={this.handleSubmit} submitText="update" initForm={this.initForm}/>
				</Col>
			</Row>
		)
				
	}
}

const mapStateToProps = (state) => {
	return {
		allApplications: state.application.list.data
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		brandActios: bindActionCreators(BrandActions, dispatch)
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateBrand)
