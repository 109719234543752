import { wrapper } from './AFormItem'

export const createCheckbox = Component => (props) => {
    let value = props.input.value
	const input = {
        ...props.input,
		value: value,
        onBlur: () => {}
	}
	
    return wrapper(Component, {...props, input, checked: value })
}
