import React from 'react'
import { Field, change } from 'redux-form'
import { map, filter, includes, isEmpty, head, get } from 'lodash'
import { Select, Button, InputNumber, Checkbox, Row, Col } from 'antd'
import { ChromePicker } from 'react-color'
import { connect } from 'react-redux'
import reactCSS from 'reactcss'

import { createItem } from '../../atoms/formItems/AItem'
import { createSelect } from '../../atoms/formItems/ASelect'
import { createCheckbox } from '../../atoms/formItems/ACheckbox'
import { createColorPicker } from '../../atoms/formItems/AColorPicker'
import UploadImage from '../../atoms/UploadImage'
import { TEXTURE_TYPES } from '../../enums/enums'

const { Option } = Select

const ASelect = createSelect(Select)
const AInputNumber = createItem(InputNumber)
const ACheckbox = createCheckbox(Checkbox)
const AUpload = createItem(UploadImage)
const AColorPicker = createColorPicker(ChromePicker)

class Textures extends React.Component {
	constructor(props) {
		super(props)

		this.newTabIndex = 1

		this.state = {
			selectedItem: null,
			displayColorPicker: null
		}
	}

	componentDidMount = () => {
		const { fields, dispatch, meta } = this.props
		
		const selectedTextures = map(fields.getAll(), (texture) => texture.type)

		const filteredTextures = filter(TEXTURE_TYPES, (texture) => !includes(selectedTextures, texture))

		if (!isEmpty(filteredTextures)) {
			const [ item, item2 ] = fields.name.split('.')
			this.setState({ selectedItem: head(filteredTextures) })

			dispatch(change(meta.form, `${item}.${item2}.texturesSelect`, head(filteredTextures) || null))
		}
	}
	
	handleTexturesChange = (selectedItem) => {
		this.setState({ selectedItem })
	}

	handleAddTexture = () => {
		const { fields, dispatch, meta } = this.props;
		const { selectedItem } = this.state
		if(!selectedItem) {
			return
		}
		
		fields.push({ type: selectedItem, scale: 1 })
		const [ item, item2 ] = fields.name.split('.')

		// get all already selected textures from redux store
		const selectedTextures = map(fields.getAll(), (texture) => {
			return texture.type
		})
		const selectedItems = [ ...selectedTextures, selectedItem]
		// filter all textures to get unassigned textures
		const filteredTextures = filter(TEXTURE_TYPES, (texture) => {
			return !includes(selectedItems, texture)
		})
		dispatch(change(meta.form, `${item}.${item2}.texturesSelect`, head(filteredTextures) || null))
		

		this.setState({ selectedItem: head(filteredTextures) })
	}
	
	handleDeleteTexture = (index) => {
		const { fields, dispatch, meta } = this.props;

		fields.remove(index)
		const items = fields.getAll()

		const [ item, item2 ] = this.props.fields.name.split('.')
		const removedItems = get(items, `[${index}].type`)
		dispatch(change(meta.form, `${item}.${item2}.texturesSelect`, removedItems || null))
		// dispatch(change(meta.form, `${item}.${item2}.textures[${index}].isImage`, false))
		this.setState({ selectedItem: removedItems })
	}

	handleClick = (index) => {
		const { displayColorPicker } = this.state
		this.setState({ 
			displayColorPicker: {
				...displayColorPicker,
				[index]: !get(displayColorPicker, `[${index}]`)
			}
		})
	};
	
	handleClose = (index) => {
		const { displayColorPicker } = this.state
		this.setState({ 
			displayColorPicker: {
				...displayColorPicker,
				[index]: false
			}
		})
	}

	render = () => {
		const { fields } = this.props
		const { displayColorPicker } = this.state
		// get all already selected textures from redux store
		const selectedTextures = map(fields.getAll(), (texture) => texture.type)
		
		// filter all textures to get unassigned textures
		const filteredTextures = filter(TEXTURE_TYPES, (texture) => !includes(selectedTextures, texture))

		// create options from unassigned textures
		const textureOptions = map(filteredTextures, (texture) => {
			return <Option value={texture} key={texture}>{texture}</Option>
		})

		// get name of parent (from variants[0].materials[0].textures to variants[0].materials[0])
		const lastDot = fields.name.lastIndexOf('.')
		const parentName = fields.name.substr(0, lastDot)

		const disabled = selectedTextures.length >= TEXTURE_TYPES.length
		return (
			<div>
				<Row>
					<Col span={18} >
						<Field 
							name={`${parentName}.texturesSelect`} 
							onChange={this.handleTexturesChange}
							component={ASelect}
						>
							{textureOptions}
						</Field>
					</Col>
					<Col span={6} >
						<Button disabled={disabled} type="primary" onClick={this.handleAddTexture}>
							Add
						</Button>
					</Col>
				</Row>
				
				{
					fields.map((texture, index) => {
						const textureValues = fields.get(index)
						const { isImage, title, type, color } = textureValues
											
						const styles = reactCSS({
							'default': {
							  color: {
								width: '36px',
								height: '14px',
								borderRadius: '2px',
								background: `${get(color, 'hex') || color || '#000'}`,
							  },
							  swatch: {
								padding: '5px',
								background: '#fff',
								borderRadius: '1px',
								boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
								display: 'inline-block',
								cursor: 'pointer',
							  },
							  popover: {
								position: 'absolute',
								zIndex: '2',
								bottom: 'calc(100% + 10px)'
							  },
							  cover: {
								position: 'fixed',
								top: '0px',
								right: '0px',
								bottom: '0px',
								left: '0px',
							  },
							},
						})
						 
						return (
							<Row className="texture-row" type="flex" align="middle" key={index}>
								<Col span={2}>{title || type}</Col>
								<Col span={4} >
									<Field name={`${texture}.scale`} min={1} defaultValue={1} component={AInputNumber} placeholder={'Scale'}/>
								</Col>

								<Col span={6} >
									<Field 
										name={`${texture}.isImage`}
										label="Use image?"
										component={ACheckbox}
										onChange={this.handleImageOrColorChange} />
								</Col>

								<Col span={6} >
									{
									isImage ? (
										<Field name={`${texture}.image`} className="avatar-uploader"
											width={100} height={100} component={AUpload} />
									) : (
										<div>
											<div style={ styles.swatch } onClick={ () => this.handleClick(index) }>
											<div style={ styles.color } />
											</div>
											{ get(displayColorPicker, `[${index}]`) ? <div style={ styles.popover }>
											<div style={ styles.cover } onClick={ () => this.handleClose(index) }/>
												<Field name={`${texture}.color`} component={AColorPicker} />
											</div> : null }
										</div>
									)
									}
								</Col>

								<Col span={6} >
									<Button type="primary" onClick={() => this.handleDeleteTexture(index)}>
										Delete
									</Button>
								</Col>
							</Row>
						)
					})
				}
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default connect(null, mapDispatchToProps)(Textures)

