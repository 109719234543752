import React from 'react'
import { Form } from 'antd'
import { get } from 'lodash'

const formItemLayout = {
	labelCol: {
	  xs: { span: 24 },
	  sm: { span: 8 },
	},
	wrapperCol: {
	  xs: { span: 24 },
	  sm: { span: 16 },
	}
}

export const wrapper = (Component, { input, meta, children, hasFeedback, label, colon, extra, help, labelCol, required, wrapperCol, ...rest }) => {
    const hasError = get(meta, 'touched') && get(meta, 'invalid')
	const helpProp = (help || hasError) && get(meta, 'error')

	const newLabelCol = labelCol || formItemLayout.labelCol
	const newWrapperCol = wrapperCol || formItemLayout.wrapperCol
	
    return (
        <Form.Item {...formItemLayout}
            label={label}
            colon={colon}
            extra={extra}
            help={helpProp}
            labelCol={newLabelCol}
            required={required}
            wrapperCol={newWrapperCol}
            validateStatus={hasError ? "error" : "success"}
            hasFeedback={hasFeedback && hasError}
        >
            <Component {...input} {...rest} >{children}</Component>
        </Form.Item>
    )
}
