import React from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { map, isEmpty, uniqBy, isArray, get } from 'lodash'
import { Form, Input, Select, Button, Modal, message, Tabs } from 'antd'

import UploadImage from '../../atoms/UploadImage'
import UploadFile from '../../atoms/UploadFile'
import Variants from './Variants'
import { createItem } from '../../atoms/formItems/AItem'
import { createSelect } from '../../atoms/formItems/ASelect'
import { required } from '../../enums/formValidations'
import { getMaterialsFromFile } from '../../utils/objFileConversion'
import { getBrands } from '../../actions/brandActions'
import { getCategoriesByAppId } from '../../actions/categoryActions'
import { MODEL_TYPES_ARRAY as modelTypes, LANGUAGES, FORMS } from '../../enums/enums'
import * as ProductLineActions from '../../actions/productLinesActions'
import * as CategoryActions from '../../actions/categoryActions'

import { getIdsFromNames } from '../../utils/helper'
import { backendAxios } from '../../utils/axiosWrapper'

const { TabPane } = Tabs
const FormItem = Form.Item
const { Option } = Select

const AInput = createItem(Input)
const ATextArea = createItem(Input.TextArea)
const ASelect = createSelect(Select)
const AUploadImage = createItem(UploadImage)
const AUploadFile = createItem(UploadFile)

class ProductForm extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			modalVisible: false,
			modelFile: null,
			isLoading: false
		}

		this.newTabIndex = 0
	}

	componentDidMount = () => {
		this.props.getBrands()
		this.props.productLinesActions.getProductLines(1, 1000, 'name:ascend', '')
		this.props.categoriesActions.getCategories(1, 1000, 'name:ascend', '')
	}

	handleGetCategoriesByAppId = (applications) => {
		this.props.getCategoriesByAppId(applications)
		this.props.change('category', [])
	}

	handleModelObjData = async (uploadedFiles) => {
		const { submitText } = this.props
		// if it is create form, get variants immediately
		if (submitText === 'create') {
			this.initializeVariants(uploadedFiles[0])
		} else {
			// show modal if it is update form
			this.setState({
				modalVisible: true,
				modelFile: uploadedFiles[0]
			})
		}
	}
	
	handleModalOk = () => {
		const { modelFile } = this.state

		this.initializeVariants(modelFile)

		this.setState({
			modalVisible: false
		})
	}
	
	handleModalCancel = () => {
		this.setState({
			modalVisible: false
		})
	}

	initializeVariants = async (uploadedFile) => {
		const { change } = this.props
		try {
			const materials = await getMaterialsFromFile(uploadedFile)
			const initMaterials = map(materials, (material) => {
				return { name: material}
			})

			change("materials", materials)
			const initVariant = [{
				name: 'Shared',
				closable: false,
				materials: initMaterials
			}]
			change("variants", initVariant)
		} catch(err) {
			console.log(err, 'ERR')
		}
	}

	handleProductThumbnailChange = (image) => {
		const { change, gallery } = this.props
		const joinedGallery = gallery ? [...image, ...gallery] : image

		change('gallery', joinedGallery)
	}

	handleProductLineChange = async(productLine) => {
		const { change, productLines } = this.props
		if (!productLine) {
			change('productLine', [])
		} else {
			const productLineId = getIdsFromNames(productLine, productLines)
			const hideLoading = message.loading('Loading in data ..', 0)
			try {
				this.setState({ isLoading: true })
				const { data } = await backendAxios.get(`/productLines/${productLineId}`)
				const applications = map(get(data, 'applications'), (app) => app.name)
				change('productLine', productLine)
				change('brand', get(data, 'brand.name') || [])
				change('applications', applications)
				change('category', get(data, 'category.name') || [])

				hideLoading()
				this.setState({ isLoading: false })
			} catch(e) {
				console.log(e)
				hideLoading()
				this.setState({ isLoading: false })
			}
		}
	}

	onChangeLanguage = (language) => {
		const { change, initForm, pristine, submit } = this.props
		if (pristine && initForm) {
			initForm(language)
		} else if (pristine) {
			change('language', language)
		} else if (!pristine && initForm){
			submit()
			initForm(language)
		} else {
			submit()
			change('language', language)
		}
	}

	render = () => {
		const {
			submitText,
			handleSubmit,
			pristine,
			reset,
			submitting,
			initialized,
			t,
			productName,
			productLines,
			productLine,
			applications,
			brands,
			categories,
			variants,
			materials,
			language
		} = this.props
		const { modalVisible, isLoading } = this.state
		
		const applicationOptions = map(applications, ({ id, name }) => {
			return <Option value={name} key={id}>{name}</Option>
		})

		const brandOptions = map(brands, ({ id, name }) => {
			return <Option value={name} key={id}>{name}</Option>
		})

		const uniqueCategories = uniqBy(categories, 'name')

		const categoryOptions = map(uniqueCategories, ({ id, name }) => {
			return <Option value={name} key={id}>{name}</Option>
		})

		const modelTypeOptions = map(modelTypes, (modelType) => {
			return <Option value={modelType} key={modelType}>{modelType}</Option>
		})

		const productLineOptions = map(productLines, ({ id, name }) => {
			return <Option value={name} key={id}>{name}</Option>
		})

		const requiredField = { required: true, validate: [required] }
		return (
			<Tabs
			onChange={this.onChangeLanguage}
			activeKey={language}
		>
			{LANGUAGES.map(pane => (
				<TabPane tab={pane} key={pane}>
					<Form onSubmit={handleSubmit} >
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="name" label={t('components:name')} placeholder={t('components:name')} { ...requiredField } component={AInput} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="description" label={t('components:description')} placeholder={t('components:description')} component={ATextArea} />
			
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="url" label={t('components:url')} placeholder={t('components:url')} {...requiredField} component={AInput} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="productThumbnail" className="avatar-uploader" label={t('components:image')} width={180} height={180}
							showCropper={true} initialized={initialized} {...requiredField} onChange={this.handleProductThumbnailChange} component={AUploadImage} />

						<Field fileName={productName} uploadAsZip labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="modelObj" label={t('components:3DModelOBJ')} placeholder={t('components:3DModelOBJ')}
							{ ...requiredField } fileCount={1} getData={this.handleModelObjData}
							component={AUploadFile} />

						{!isEmpty(variants) && <FieldArray name="variants" component={Variants} materials={materials} />}

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="modelSFBUrl" label={t('components:modelSFBUrl')} placeholder={t('components:modelSFBUrl')}
							{ ...requiredField } fileCount={1} component={AUploadFile} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="modelType" label={t('components:3DModelType')} placeholder={t('components:3DModelType')} { ...requiredField } component={ASelect}>
								{modelTypeOptions}
						</Field>

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="audioRecord" label={t('components:audioRecord')} placeholder={t('components:audioRecord')}
							fileCount={1} component={AUploadFile} />
						
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="gallery" className="avatar-uploader" label={t('components:gallery')} multiple={true}
							{ ...requiredField } initialized={initialized} component={AUploadImage} />

						<Field 
							labelCol={{ span: 2 }}
							wrapperCol={{ span: 20 }}
							name="productLine"
							label={t('components:productLine')}
							placeholder={t('components:productLinePlaceholder')}
							onChange={this.handleProductLineChange}
							allowClear
							showSearch
							component={ASelect}
						>
								{productLineOptions}
						</Field>
						<Field 
							labelCol={{ span: 2 }}
							wrapperCol={{ span: 20 }}
							name="brand"
							label={t('components:brand')}
							placeholder={t('components:brandPlaceholder')}
							disabled={!!productLine}
							required={!productLine}
							showSearch
							component={ASelect}
						>
								{brandOptions}
						</Field>

						<Field 
							labelCol={{ span: 2 }}
							wrapperCol={{ span: 20 }}
							name="applications"
							label={t('components:applications')}
							placeholder={t('components:applicationsPlaceholder')}
							mode="multiple"
							disabled={!!productLine}
							required={!productLine}
							onChange={this.handleGetCategoriesByAppId}
							component={ASelect} >
							{applicationOptions}
						</Field>

						<Field
							labelCol={{ span: 2 }}
							wrapperCol={{ span: 20 }}
							name="category"
							label={t('components:categories')}
							placeholder={t('components:categoriesPlaceholder')}
							required={!isEmpty(categoryOptions) && !productLine}
							component={ASelect}
							showSearch
							disabled={isEmpty(categoryOptions) || !!productLine}>
							{categoryOptions}
						</Field>
						
						<FormItem >
							<Button type="primary" disabled={pristine || submitting || isLoading} htmlType="submit" style={{ marginRight: "10px" }}>
								{t(`components:${submitText}`)}
							</Button>

							<Button disabled={pristine || submitting || isLoading} onClick={reset}>
								{t('components:resetValues')}
							</Button>
						</FormItem>

						<Modal visible={modalVisible} title="Warning" onOk={this.handleModalOk} onCancel={this.handleModalCancel} style={{ top: 20 }}
							footer={[
								<Button key="back" onClick={this.handleModalCancel}>
									No
								</Button>,
								<Button key="submit" type="primary" onClick={this.handleModalOk}>
									Yes
								</Button>,
							]}
						>
							<p>You are changing model's geometry, which may cause unexpected behaviour, do you also want to reload all materials?</p>
						</Modal>
					</Form>
				</TabPane>
			))}
		</Tabs>)
	}
}

const mapStateToProps = (state) => {
	const modelObj = selector && selector(state, "3DModelOBJ")
	const variants = selector && selector(state, "variants")
	const materials = selector && selector(state, "materials")
	const productName = selector && selector(state, "name")
	const gallery = selector && selector(state, "gallery")
	const language = selector && selector(state, "language")
	let productLine = selector && selector(state, 'productLine')
	if(isArray(productLine) && isEmpty(productLine)) {
		productLine = null
	}
	return {
		applications: state.application.list.data,
		brands: state.brand.list.data,
		categories: state.category.list.data,
		productLines: state.productLine.list.data,
		productName,
		productLine,
		gallery,
		// state of variants
		variants,
		materials,
		modelObj,
		language
	}
}

const selector = formValueSelector(FORMS.PRODUCT)

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		getBrands: () => {
			dispatch(getBrands(1, 1000, 'name:ascend', ''))
		},
		getCategoriesByAppId: (applications) => {
			dispatch(getCategoriesByAppId(applications))
		},
		categoriesActions: bindActionCreators(CategoryActions, dispatch),
		productLinesActions: bindActionCreators(ProductLineActions, dispatch)
	}
}

const form = reduxForm({
	form: FORMS.PRODUCT,
	destroyOnUnmount: true
})(ProductForm)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation('components')
)(form)
