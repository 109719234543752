import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import jwt from 'jsonwebtoken'
import { Layout, Breadcrumb, Icon } from 'antd'
import { getUserDetail } from '../../actions/authActions'
import { getApplications } from '../../actions/applicationActions'
import SideMenu from './MainSideMenu'
import LogoutButton from '../auth/LogoutButton'

import createBreadcrumb from '../../utils/breadcrumb'

const {
	Header, Footer, Sider, Content,
} = Layout

class MainLayout extends React.Component {

	componentDidMount() {
		const { token } = this.props
		const decodedToken = jwt.decode(token)

		this.props.getApplications()
		this.props.getUserDetail(decodedToken.id)
	}

	render = () => {
		const { location, menuItem, openMenuItem } = this.props
		const breadcrumbItems = createBreadcrumb(location)

		const { userDetail } = this.props
		const { firstName, lastName } = userDetail
		return (
			<Layout style={{ minHeight: '100vh' }}>
				<Sider>
					<div className="logoHeader">
						<Link to={'/banners'} style={{textDecoration: 'none'}}>
							<span style={{color: 'white', fontSize: '18px'}}>ArVisual Admin</span>
							<span style={{color: 'grey'}}>  {process.env.REACT_APP_VERSION}</span>
						</Link>
						{process.env.REACT_APP_SERVER_TYPE && <p style={{ color: 'red' }}>{process.env.REACT_APP_SERVER_TYPE}</p>}
					</div>

					<SideMenu openMenuItem={openMenuItem} menuItem={menuItem}/>
				</Sider>

				<Layout>
					<Header style={{ background: '#fff', padding: 0 }}>
						<div className="logoutBtnHeader">
							<Link to={'/userAccount'} style={{textDecoration: 'none'}}>
								<Icon type="user" />  Welcome {firstName} {lastName}
							 </Link>
							<LogoutButton />
						</div>
					</Header>

					<Content style={{ margin: "0 16px" }}>
						<Breadcrumb style={{ margin: "16px 0" }}>
							{breadcrumbItems}
						</Breadcrumb>
						<div style={{ padding: 24, background: "#fff", minHeight: '100%' }}>
							{this.props.children}
						</div>
					</Content>
					
					<Footer style={{ textAlign: "center" }}>
						Copyright © AR Visual, s. r. o. 2018
					</Footer>
				</Layout>
			</Layout>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		token: state.auth.accessToken,
		userDetail: state.auth.userDetail
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getApplications: () => {
			dispatch(getApplications())
		},
		getUserDetail: (userID) => {
			dispatch(getUserDetail(userID))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
