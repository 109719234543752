import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import applicationReducer from './applicationReducer'
import authReducer from './authReducer'
import brandReducer from './brandReducer'
import bannerReducer from './bannerReducer'
import categoryReducer from './categoryReducer'
import productReducer from './productReducer'
import userReducer from './userReducer'
import productLinesReducer from './productLinesReducer'

const rootReducer = combineReducers({
	application: applicationReducer,
	auth: authReducer,
	banner: bannerReducer,
	brand: brandReducer,
	category: categoryReducer,
	product: productReducer,
	user: userReducer,
	productLine: productLinesReducer,
	form: formReducer
})

export default rootReducer
