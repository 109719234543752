import { isArray } from 'lodash'

export const required = (value) => {
	if(isArray(value) && value.length < 1) {
		return 'Required'
	}
	
	if(!value && typeof value !== 'number') {
		return 'Required'
	}
	
	return undefined
}

export const maxLength = (max) => (value) =>
	value && value.length > max ? `Must be ${max} characters or less` : undefined

export const minLength = (min) => (value) =>
	value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minValue = (min) => (value) =>
	value && value < min ? `Must be at least ${min}` : undefined

export const email = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
		? 'Invalid email address'
		: undefined

export const passwordsMatch = (value, allValues) => 
	value !== allValues.password ? `Passwords don't match` : undefined

export const alphaNumeric = (value) =>
	value && /[^a-zA-Z0-9 ]/i.test(value)
		? 'Only alphanumeric characters'
		: undefined

export const number = (value) =>
	value && isNaN(Number(value)) ? 'Must be a number' : undefined
