import { map, isEmpty, get, isArray } from 'lodash'
import { message } from 'antd'

import { backendAxios } from '../utils/axiosWrapper'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'
import { getIdsFromNames, convertNullToUndefined } from '../utils/helper'

/**
 * @param {Integer} pageValue wanted page
 * @param {Integer} pageSizeValue wanted number of items on one page
 * @param {} orderValue wanted order
 * @param {String} searchValue wanted search value
 */
export const getCategories = (pageValue = 1, pageSizeValue, orderValue, searchValue) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.category.GET_CATEGORIES_REQUEST })
			const { actualPage, pageSize, order, search } = getState().category.list

			const pageResult = pageValue || actualPage
			const pageSizeResult = pageSizeValue || pageSize
			const orderResult = orderValue === '' ? null : orderValue || order
			const searchResult = searchValue === '' ? null : searchValue || search

			const categories = await backendAxios.get('/categories', {
				params: {
					limit: pageSizeResult,
					page: pageResult,
					order: orderResult,
					search: searchResult
				}
			})

			// store listing data
			dispatch({ type: actionType.category.GET_CATEGORIES_SUCCESS, data: { categories: categories.data.rows, totalCategories: categories.data.count } })

			// store listing params
			dispatch({ type: actionType.category.SET_LISTING_PARAMS, data: { page:pageResult, pageSize: pageSizeResult, order: orderResult, search: searchResult }})
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.category.GET_CATEGORIES_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const getCategory = (categoryID, language) => {
	return async (dispatch) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.category.GET_CATEGORY_REQUEST })
			
			const category = await backendAxios.get(`/categories/${categoryID}`, {
				headers: {
					'accept-language': language
				}
			})

			dispatch({ type: actionType.category.GET_CATEGORY_SUCCESS, category: category.data })
			
			hideLoading()
			return category.data
		} catch (err) {
			dispatch({ type: actionType.category.GET_CATEGORY_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const createCategory = ({ name, image, applications }) => {
	return async (dispatch, getState) => {
		
	}
}

export const updateCategory = (categoryID, body) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Updating in progress..', 0)
		try {
			dispatch({ type: actionType.category.UPDATE_CATEGORY_REQUEST })

			// modified body for request (null->undefined)
			const payload = convertNullToUndefined(body)

			const { applications, image } = payload

			// get applications ids
			const allApplications = getState().application.list.data
			const applicationIds = getIdsFromNames(applications, allApplications)

			let resultImage
			if(isArray(image)){
				resultImage = image && image[0].url
			} else {
				resultImage = image
			}

			// final data for request
			const category = {
				...payload, image: resultImage, applicationIds
			}
						
			const { data } = await backendAxios.put(`/categories/${categoryID}`, category)

			dispatch({ type: actionType.category.UPDATE_CATEGORY_SUCCESS })
			
			hideLoading()
			message.success(`Category with ID: ${get(data, 'id')} was updated`, 3)
		} catch (err) {
			dispatch({ type: actionType.category.UPDATE_CATEGORY_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Updating')
		}
	}
}

export const deleteCategory = (categoryID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Deleting in progress..', 0)
		try {
			dispatch({ type: actionType.category.DELETE_CATEGORY_REQUEST })

			const { actualPage, pageSize, totalCategories } = getState().category.list
			
			const category = await backendAxios.delete(`/categories/${categoryID}`)

			dispatch({ type: actionType.category.DELETE_CATEGORY_SUCCESS, category: category.data })
			
			hideLoading()

			// after update is done, recalculate actual page and get all categories
			const pageCheck = ((totalCategories - 1) / pageSize) + 1
			const pageResult = actualPage >= pageCheck ? (actualPage - 1) : actualPage

			dispatch(getCategories((pageResult, undefined, undefined, undefined)))
		} catch (err) {
			dispatch({ type: actionType.category.DELETE_CATEGORY_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Deleting')
		}
	}
}

export const getCategoriesByAppId = (applications) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.category.GET_CATEGORIES_BY_APP_ID_REQUEST })

			// get applications ids
			const allApplications = getState().application.list.data
			const allApplicationIds = isEmpty(applications) && map(allApplications, (application) => {
				return application.id
			})
			const applicationIds = allApplicationIds || getIdsFromNames(applications, allApplications)

			const categories = await backendAxios.get('/categoriesByApplicationId', {
				params: {
					applicationIds
				}
			})

			// store listing data
			dispatch({ type: actionType.category.GET_CATEGORIES_BY_APP_ID_SUCCESS, data: { categories: categories.data.rows, totalCategories: categories.data.count } })

			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.category.GET_CATEGORIES_BY_APP_ID_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}
