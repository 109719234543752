import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError, initialize } from 'redux-form'
import { map, get, isEmpty, uniqBy, isArray } from 'lodash'
import { Row, Col, message } from 'antd'
import { compose, bindActionCreators } from 'redux'

import * as ProductLinesActions from '../../actions/productLinesActions'
import ProductLineForm from './ProductLineForm'
import { ROLE_TYPE, FORMS, LANGUAGE } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import { getIdsFromNames } from '../../utils/helper'
import { backendAxios } from '../../utils/axiosWrapper'
import errorHandler from '../../utils/errorHandler'

class UpdateProductLine extends Component {

	initForm = async(language) => {
		const { computedMatch, productLinesActions, dispatch } = this.props
		const { productLineID } = computedMatch.params
		const productLine = await productLinesActions.getProductLine(productLineID, language)

		const assignedApps = map(get(productLine, 'applications', []), (application) => application.name)

		const brand = get(productLine, 'brand.name')
		const category = get(productLine, 'category.name')
		
		const editedInitValues = {
			...productLine,
			applications: assignedApps,
			brand,
			thumbnail: get(productLine, 'thumbnail'),
			category,
			language
		}
		dispatch(initialize(FORMS.PRODUCT_LINE, editedInitValues))
	}

	componentDidMount = () => {
		this.initForm(LANGUAGE.SK)
	}

	handleSubmit = async (values) => {

		const { dispatch, applications, categories, brands } = this.props
		const uniqueCategories = uniqBy(categories, 'name')
	
		if(isEmpty(values.category) && !isEmpty(uniqueCategories)) {
			throw new SubmissionError({
				category: 'Required'
			})
		} 

		const { productLineID } = this.props.computedMatch.params

		const hideLoading = message.loading('Updating in progress..', 0)

		try {

			// get applications ids
			const applicationIds = getIdsFromNames(values.applications, applications)

			// get category id
			let categoryId = getIdsFromNames(values.category, categories)

			// get brand id
			const brandId = getIdsFromNames(values.brand, brands)

			const thumbnail = get(values, 'thumbnail')
			let resultImage
			if(isArray(thumbnail)){
				resultImage = get(thumbnail, '[0].url')
			} else {
				resultImage = thumbnail
			}
			if(isArray(categoryId) && isEmpty(categoryId)) {
				categoryId = undefined
			}

			const productLineData = {
				applicationIds,
				categoryId,
				brandId,
				thumbnail: resultImage,
				language: get(values, 'language'),
				name: get(values, 'name')
			}
			const { data } = await backendAxios.put(`/productLines/${productLineID}`, productLineData)

			hideLoading()
			message.success(`Product line with ID: ${get(data, 'id')} was updated`, 3)
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Creating')
		}
		return
		// is important return promise from handleSubmit
	}

	render = () => {
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Update product</h1>
					<ProductLineForm onSubmit={this.handleSubmit} submitText="update" initForm={this.initForm} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		categories: state.category.list.data,
		applications: state.application.list.data,
		brands: state.brand.list.data,
		productLine: state.productLine.detail.data
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		productLinesActions: bindActionCreators(ProductLinesActions, dispatch)
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateProductLine)
