import React, { Component } from "react"
import PropTypes from "prop-types"
import { Redirect } from 'react-router'
import { connect } from "react-redux"
import { get, some, indexOf } from 'lodash'

export const checkPermissions = (roles = [], allowed = [], except = []) => {
	if (except.length > 0 && some(except, elem => indexOf(roles, elem) > -1)) {
		return false
	}
	if (allowed.length > 0) {
		if (some(allowed, elem => indexOf(roles, elem) > -1)) {
			return true
		}
		return false
	}
	return true
}

export const withPermissions = (allowed = [], except = []) =>
	(WrappedComponent) => {
		class WithPermissionsClass extends Component {
			static propTypes = {
				roles: PropTypes.array.isRequired,
			}
			
			constructor(props) {
				super(props)
				this.state = {
					visible: checkPermissions(props.roles, allowed, except)
				}
			}

			componentDidUpdate() {
				const { roles } = this.props
				const { visible } = this.state
				this.checkValidPermissions(roles, visible)
			}

			checkValidPermissions = (roles, visible = false) => {
				const newVisibility = checkPermissions(roles, allowed, except)
				if (visible !== newVisibility) {
					this.setState({
						visible: newVisibility
					})
				}
			}

			render =() => {
				const { visible } = this.state
				if (visible) {
					return (<WrappedComponent {...this.props} />)
				} else {
					return <Redirect to={'/403'} />
				}

			}
		}

		const mapStateToProps = state => {
			return {
				roles: [get(state, 'auth.userDetail.role')]
			}
		}

		return connect(mapStateToProps)(WithPermissionsClass)
	}

class Permissions extends React.Component {
	static propTypes = {
		roles: PropTypes.array.isRequired,
		allowed: PropTypes.arrayOf(PropTypes.string),
		except: PropTypes.arrayOf(PropTypes.string),
		render: PropTypes.func,

	}
	
	constructor(props) {
		super(props)

		this.state = {
			hasPermissions: checkPermissions(props.roles, props.allowed, props.except)
		}
	}

	componentDidUpdate() {
		this.checkValidPermissions()
	}

	checkValidPermissions = () => {
		const { roles, allowed, except } = this.props
		const { hasPermissions } = this.state

		const newVisibility = checkPermissions(roles, allowed, except)
		if (hasPermissions !== newVisibility) {
			this.setState({
				hasPermissions: newVisibility
			})
		}
	}

	render = () => {
		const { hasPermissions } = this.state
		
		if (hasPermissions) {
			return this.props.children
		}
		
		return null

	}
}

const mapStateToProps = state => {
	return {
		roles: [get(state, 'auth.userDetail.role')]
	}
}

export default connect(mapStateToProps)(Permissions)

