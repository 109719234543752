import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import jwt from 'jsonwebtoken'
import qs from 'qs'
import { has } from 'lodash'
import { message } from 'antd'
import ChangePasswordForm from './ChangePasswordForm'
import { changePassword, resetChangePasswordState } from '../../actions/authActions'

const ReduxFormChangePassword = reduxForm({ form: 'changePassword' })(ChangePasswordForm)

class ChangePassword extends Component {
	constructor(props) {
		super(props)

		this.state = {
			token: null,
			redirectTo: null
		}
	}

	componentDidMount = () => {
		const { t, resetChangePasswordState } = this.props

		resetChangePasswordState()

		const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		if (has(queryParams, 'token')) {
			const decodedToken = jwt.decode(queryParams.token)

			if (decodedToken && decodedToken.aud === 'passReset' && decodedToken.exp > (Date.now() / 1000)) {
				this.setState({
					token: queryParams.token
				})
			} else {
				// invalid token -> redirect
				message.error(t('components:invalidToken'), 5)
				this.setState({
					redirectTo: '/forgot-password'
				})
			}
		} else {
			// token is not provided -> redirect
			message.error(t('components:tokenNotProvided'), 5)
			this.setState({
				redirectTo: '/forgot-password'
			})
		}
	}

	handleSubmit = (values) => {
		const { changePassword } = this.props
		const { token } = this.state
		changePassword({...values, token})
	}

	render() {
		const { passwordChanged } = this.props
		const { token, redirectTo } = this.state

		if (passwordChanged) {
			return <Redirect to='/login' />
		}

		if (redirectTo) {
			return <Redirect to={redirectTo} />
		}

		return (
			<ReduxFormChangePassword onSubmit={this.handleSubmit} token={token} />
		)
				
	}
}

const mapStateToProps = (state) => {
	return {
		passwordChanged: state.auth.passwordChanged
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		changePassword: ({ password, confirmPassword, token }) => {
			dispatch(changePassword({ password, confirmPassword, token }))
		},
		resetChangePasswordState: () => {
			dispatch(resetChangePasswordState())
		}
	}
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation('components')
)(ChangePassword)
