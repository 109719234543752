import React from 'react'
import { Row, Col } from 'antd'

class AuthLayout extends React.Component {
	render = () => {
		return (
			<Row type="flex" justify="center" align="middle" className="height100">
				<Col span={4}>
					{this.props.children}
				</Col>
			</Row>
		)
	}
}

export default AuthLayout
