import { message } from 'antd'
import { map } from 'lodash'
import { backendAxios } from '../utils/axiosWrapper'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'
import { getCategoriesByAppId } from './categoryActions'

/**
 * @param {Integer} pageValue wanted page
 * @param {Integer} pageSizeValue wanted number of items on one page
 * @param {} orderValue wanted order
 * @param {String} searchValue wanted search value
 */
export const getProductLines = (pageValue = 1, pageSizeValue, orderValue, searchValue) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.productLines.GET_PRODUCT_LINES_REQUEST })
			const { actualPage, pageSize, order } = getState().productLine.list

			const pageResult = pageValue || actualPage
			const pageSizeResult = pageSizeValue || pageSize
			const orderResult = orderValue === '' ? null : orderValue || order
			const searchResult = searchValue === '' ? null : searchValue

			const productLines = await backendAxios.get('/productLines', {
				params: {
					limit: pageSizeResult,
					page: pageResult,
					order: orderResult,
					search: searchResult
				}
			})

			// store listing data
			dispatch({
				type: actionType.productLines.GET_PRODUCT_LINES_SUCCESS,
				data: {
					productLines: productLines.data.rows,
					totalProductLines: productLines.data.count,
					page: pageResult,
					pageSize: pageSizeResult,
					order: orderResult,
					search: searchResult
				}
			})
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.productLines.GET_PRODUCT_LINES_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const getProductLine = (productLineID, language) => {
	return async (dispatch) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.productLines.GET_PRODUCT_LINE_REQUEST })
			
			const { data } = await backendAxios.get(`/productLines/${productLineID}`, {
				headers: {
					'accept-language': language
				}
			})

			const appNames = map(data.applications, (application) => application.name)
			dispatch(getCategoriesByAppId(appNames))
			
			dispatch({ type: actionType.productLines.GET_PRODUCT_LINE_SUCCESS, productLine: data })
			
			hideLoading()
			return data
		} catch (err) {
			dispatch({ type: actionType.productLines.GET_PRODUCT_LINE_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Loading')
			return err
		}
	}
}
