import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map, isArray, get } from 'lodash'
import { Row, Col, message } from 'antd'
import { compose, bindActionCreators } from 'redux'
import { initialize, reset } from "redux-form"

import CategoryForm from './CategoryForm'
import * as CategoryActions from '../../actions/categoryActions'
import { withPermissions } from '../../utils/permissionsHoc'
import { ROLE_TYPE, LANGUAGE, FORMS } from '../../enums/enums'
import { convertNullToUndefined, getIdsFromNames } from '../../utils/helper';
import { backendAxios } from '../../utils/axiosWrapper';
import errorHandler from '../../utils/errorHandler';

class UpdateCategory extends Component {

	initForm = async(language) => {
		const { computedMatch, categoryActions, dispatch } = this.props
		const { categoryID } = computedMatch.params

		const category = await categoryActions.getCategory(categoryID, language)

		const applications = map(get(category, 'applications'), 'name')
		const initValue = { ...category, applications, language }

		dispatch(reset(FORMS.CATEGORY))
		dispatch(initialize(FORMS.CATEGORY, initValue))
	}

	componentDidMount() {
		this.initForm(LANGUAGE.SK)
	}

	handleSubmit = async(values) => {
		const { dispatch, computedMatch, allApplications } = this.props 
		const { categoryID } = computedMatch.params

		const hideLoading = message.loading('Updating in progress..', 0)
		try {

			// modified body for request (null->undefined)
			const payload = convertNullToUndefined(values)

			const { applications, image } = payload

			// get applications ids
			const applicationIds = getIdsFromNames(applications, allApplications)

			let resultImage
			if(isArray(image)){
				resultImage = image && image[0].url
			} else {
				resultImage = image
			}

			// final data for request
			const category = {
				...payload, image: resultImage, applicationIds, language: values.language
			}
						
			const { data } = await backendAxios.put(`/categories/${categoryID}`, category)

			hideLoading()
			message.success(`Category with ID: ${get(data, 'id')} was updated`, 3)
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Updating')
		}
	}

	render() {
		
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Update category</h1>
					<CategoryForm onSubmit={this.handleSubmit} submitText="update" initForm={this.initForm} />
				</Col>
			</Row>
		)
				
	}
}
const mapStateToProps = (state) => ({
	allApplications: state.application.list.data,
})

const mapDispatchToProps = (dispatch) => {
	return {
		categoryActions: bindActionCreators(CategoryActions, dispatch)
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateCategory)
