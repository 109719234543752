import React from 'react'
import { Field } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Form, Input, Select, Button } from 'antd'
import { createItem } from '../../atoms/formItems/AItem'
import { createSelect } from '../../atoms/formItems/ASelect'
import  { required } from '../../enums/formValidations'
import { ROLE_TYPES as roles } from '../../enums/enums'

const FormItem = Form.Item
const Option = Select.Option

const AInput = createItem(Input)
const ASelect = createSelect(Select)

class UpdateUserForm extends React.Component {
	
	render = () => {
		const { submitText, disabledEmail, disabledRole } = this.props
		const { handleSubmit, pristine, reset, submitting } = this.props
		const { t } = this.props

		const roleOptions = roles && map(roles, (role) => {
			return <Option value={role} key={role}>{role}</Option>
		})

		const requiredField = { required: true, validate: [required] }
				
		return (
			<Form onSubmit={handleSubmit} >
				<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="firstName" label={t('components:firstName')} placeholder={t('components:firstName')} {...requiredField} component={AInput} />

				<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="lastName" label={t('components:lastName')} placeholder={t('components:lastName')} {...requiredField} component={AInput} />
	
				<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="email" label={t('components:email')} placeholder={t('components:email')} disabled={disabledEmail} {...requiredField} component={AInput} />

				<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="role" label={t('components:role')} placeholder={t('components:rolesPlaceholder')}
					disabled={disabledRole} {...requiredField} component={ASelect} >
					{roleOptions}
				</Field>
				
				<FormItem>
					<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
						{t(`components:${submitText}`)}
					</Button>

					<Button disabled={pristine || submitting} onClick={reset}>
						{t('components:resetValues')}
					</Button>
				</FormItem>
			</Form>
		)
	}
}

export default withTranslation('components')(UpdateUserForm)
