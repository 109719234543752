import React from 'react'
import {Link } from 'react-router-dom'
import pathToRegexp from 'path-to-regexp'
import { Breadcrumb } from 'antd'
import { includes } from 'lodash'

const breadcrumbNameMap = {
	'/banners': 'Banners',
	'/banners/create': 'Create',
	'/banners/update': 'Update',
	'/brands': 'Brands',
	'/brands/create': 'Create',
	'/brands/update': 'Update',
	'/categories': 'Categories',
	'/categories/create': 'Create',
	'/categories/update': 'Update',
	'/products': 'Products',
	'/products/create': 'Create',
	'/products/update': 'Update',
	'/administration/users': 'Users',
	'/administration/users/create': 'Create',
	'/administration/users/update': 'Update',
	'/userAccount': 'Account',
	'/productLines': 'Product Lines',
	'/productLines/create': 'Create',
	'/productLines/update': 'Update'
}

export default (location) => {

	const pathSnippets = location.pathname.split('/').filter(i => i)
	const breadcrumbKeyMap = Object.keys(breadcrumbNameMap)
    const breadcrumbItems = []
    
	pathSnippets.forEach((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`

		breadcrumbKeyMap.forEach((item) => {
			if (pathToRegexp(item).test(url)) {
				breadcrumbItems.push(
					<Breadcrumb.Item key={url}>
						{includes(item, 'update') ? (
							breadcrumbNameMap[item]
						) : (
							<Link to={url}>
								{breadcrumbNameMap[item]}
							</Link>
						)}
					</Breadcrumb.Item>
				)
			}
		})
	})

	return breadcrumbItems
}
