import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { map, isEmpty, uniqBy } from 'lodash'
import { Form, Input, Select, Button, Tabs } from 'antd'

import UploadImage from '../../atoms/UploadImage'
import { createItem } from '../../atoms/formItems/AItem'
import { createSelect } from '../../atoms/formItems/ASelect'
import  { required } from '../../enums/formValidations'
import { getBrands } from '../../actions/brandActions'
import { getCategoriesByAppId } from '../../actions/categoryActions'
import { LANGUAGES, FORMS } from '../../enums/enums'

const FormItem = Form.Item
const { Option } = Select
const { TabPane } = Tabs

const AInput = createItem(Input)
const ASelect = createSelect(Select)
const AUpload = createItem(UploadImage)

class ProductLineForm extends React.Component {

	componentDidMount = () => {
		this.props.getBrands()
	}

	handleGetCategoriesByAppId = (applications) => {
		this.props.getCategoriesByAppId(applications)
		this.props.change('category', [])
	}

	onChangeLanguage = (language) => {
		const { change, initForm, pristine, submit } = this.props
		if (pristine && initForm) {
			initForm(language)
		} else if (pristine) {
			change('language', language)
		} else if (!pristine && initForm){
			submit()
			initForm(language)
		} else {
			submit()
			change('language', language)
		}
	}

	render = () => {
		const { applications, submitText, brands, categories, language, t, handleSubmit, pristine, reset, submitting, initialized } = this.props

		const applicationOptions = applications && map(applications, (application) => {
			const { id, name } = application
			return <Option value={name} key={id}>{name}</Option>
		})

		const requiredField = { required: true, validate: [required] }

		const uniqueCategories = uniqBy(categories, 'name')
		const brandOptions = map(brands, ({ id, name }) => {
			return <Option value={name} key={id}>{name}</Option>
		})
		const categoryOptions = map(uniqueCategories, ({ id, name }) => {
			return <Option value={name} key={id}>{name}</Option>
		})
				
		return (<Tabs onChange={this.onChangeLanguage} activeKey={language}>
			{LANGUAGES.map(pane => (
				<TabPane tab={pane} key={pane}>
					<Form onSubmit={handleSubmit} >
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="name" label={t('components:name')} placeholder={t('components:name')} {...requiredField} component={AInput} />
			
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="thumbnail" className="avatar-uploader" label="Image" {...requiredField}
							width={250} height={250} showCropper={true} initialized={initialized} component={AUpload} />

						<Field 
							labelCol={{ span: 2 }}
							wrapperCol={{ span: 20 }}
							name="brand"
							label={t('components:brand')}
							placeholder={t('components:brandPlaceholder')}
							showSearch
							{ ...requiredField }
							component={ASelect}
						>
								{brandOptions}
						</Field>

						<Field 
							labelCol={{ span: 2 }}
							wrapperCol={{ span: 20 }}
							name="applications"
							label={t('components:applications')}
							placeholder={t('components:applicationsPlaceholder')}
							mode="multiple"
							{...requiredField}
							onChange={this.handleGetCategoriesByAppId}
							component={ASelect} >
							{applicationOptions}
						</Field>

						<Field
							labelCol={{ span: 2 }}
							wrapperCol={{ span: 20 }}
							name="category"
							label={t('components:categories')}
							placeholder={t('components:categoriesPlaceholder')}
							required={!isEmpty(categoryOptions)}
							component={ASelect}
							showSearch
							disabled={isEmpty(categoryOptions)}>
							{categoryOptions}
						</Field>
						
						<FormItem>
							<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
								{t(`components:${submitText}`)}
							</Button>

							<Button disabled={pristine || submitting} onClick={reset}>
							{t('components:resetValues')}
							</Button>
						</FormItem>
					</Form>
				</TabPane>
			))}
		</Tabs>
			
		)
	}
}

const selector = formValueSelector(FORMS.PRODUCT_LINE)

const mapStateToProps = (state) => {

	return {
		applications: state.application.list.data,
		brands: state.brand.list.data,
		categories: state.category.list.data,
		language: selector(state, "language")
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		getBrands: () => {
			dispatch(getBrands(1, 1000, 'name:ascend', ''))
		},
		getCategoriesByAppId: (applications) => {
			dispatch(getCategoriesByAppId(applications))
		}
	}
}

const form = reduxForm({
	form: FORMS.PRODUCT_LINE,
	destroyOnUnmount: true
})(ProductLineForm)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation('components')
)(form)
