import actionType from '../enums/actions'

const initState = {
	isLoggedIn: false,
	accessToken: '',
	tokenIsExpired: false,
	isLoading: false,
	isFailure: false,
	errorMessage: '',
	passwordChanged: false,
	userDetail: {
		id: 0,
		firstName: '',
		lastName: '',
		email: '',
		role: ''
	}
}

const authReducer = (state = initState, action) => {
	switch (action.type) {
		case actionType.auth.LOGIN_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case actionType.auth.LOGIN_SUCCESS:
			return {
				...state,
				isLoading: false,
				isFailure: false,
				isLoggedIn: true,
				accessToken: action.accessToken,
				userDetail: action.user
			}
		case actionType.auth.LOGIN_ERROR:
			return {
				...state,
				isLoading: false,
				isFailure: true,
				errorMessage: action.err
			}
		case actionType.auth.LOGIN_ERROR_CLEAR:
			return {
				...state,
				isLoading: false,
				isFailure: false,
				errorMessage: ''
			}
		case actionType.auth.LOGOUT_USER_REQUEST: {
			return {
				...initState,
				tokenIsExpired: false
			}
		}
		case actionType.auth.LOGOUT_EXPIRED_JWT: {
			return {
				...initState,
				tokenIsExpired: true,
			}
		}
		case actionType.auth.GET_USER_DETAIL_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case actionType.auth.GET_USER_DETAIL_SUCCESS:
			return {
				...state,
				isLoading: false,
				isFailure: false,
				isLoggedIn: true,
				userDetail: action.user
			}
		case actionType.auth.GET_USER_DETAIL_ERROR:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		case actionType.auth.UPDATE_USER_DETAIL_REQUEST: {
			return {
				...state,
				isLoading: true,
			}
		}
		case actionType.auth.UPDATE_USER_DETAIL_SUCCESS:
			return {
				...state,
				isLoading: false,
				isFailure: false,
				isLoggedIn: true,
				userDetail: action.user
			}
		case actionType.auth.UPDATE_USER_DETAIL_ERROR:
			return {
				...state,
				isLoading: false,
				isFailure: true
			}
		case actionType.auth.CHANGE_PASSWORD_SUCCESS:
			return {
				...state,
				passwordChanged: true
			}
		case actionType.auth.CHANGE_PASSWORD_RESET:
			return {
				...state,
				passwordChanged: false
			}
		default:
			return state
	}
	
}

export default authReducer
