import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { camelCase, get } from 'lodash'
import { Table, Button, Popconfirm, Divider, Row, Col } from 'antd'
import { compose } from 'redux'

import SearchProduct from './SearchProduct'
import { getProducts, deleteProduct, duplicateProduct } from '../../actions/productActions'
import { withPermissions } from '../../utils/permissionsHoc'
import { ROLE_TYPE } from '../../enums/enums'

class ProductList extends React.Component {

	columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			sorter: true,
			defaultSortOrder: 'descend',
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Image',
			dataIndex: 'image',
			key: 'image',
			render: (text, product) => {
				if(get(product, 'image')){
					return <img alt={get(product, 'name')} style={{display: 'block', maxWidth: '180px', maxHeight: '70px', width: 'auto', height: 'auto'}} src={get(product, 'image')}/>
				} else {
					return ''
				}
			}
		}, {
			title: 'Category',
			dataIndex: 'category.name',
			key: 'categoriesName',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Brand',
			dataIndex: 'brand.name',
			key: 'brandsName',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Languages',
			dataIndex: 'languages',
			key: 'laguages',
			sorter: false
		}, {
			title: 'Action',
			key: 'action',
			render: (text, product) => {
				return this.props.totalProducts >= 1
					? (
						<span>
							<Link to={'/products/update/' + product.id}>Update</Link>
							<Divider type="vertical" />
							<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(product.id)}>
								<Link to={'#'}>Delete</Link>
							</Popconfirm>
							<Divider type="vertical" />
							<Popconfirm title="Sure to duplicate?" onConfirm={() => this.handleDuplicate(product.id)}>
								<Link to={'#'}>Duplicate</Link>
							</Popconfirm>
						</span>
					) : null
			}
		}
	]

	componentDidMount() {
		this.props.getProducts(undefined, undefined, 'id:descend', '')
	}

	handleDelete = (productID) => {
		this.props.deleteProduct(productID)
	}

	handleDuplicate = (productID) =>{
		this.props.duplicateProduct(productID)
	}

	handleSearch = (value) => {
		this.props.getProducts(undefined, undefined, undefined, value.search)
	}
	
	handlePageChange = (page) => {
		this.props.getProducts(page, undefined, undefined, undefined)
	}

	handlePageSizeChange = (current, pageSize) => {
		this.props.getProducts(current, pageSize, undefined, undefined)
	}

	handleChange = (pagination, filters, sorter) => {
		// get correct format of sort parameter
		// exp. productName:ascend
		const orderValue = `${camelCase(sorter.columnKey)}:${sorter.order}`
		if (sorter.field) {
			this.props.getProducts(pagination.current, pagination.pageSize, orderValue, undefined)
		} else {
			this.props.getProducts(pagination.current, pagination.pageSize, '', undefined)
		}
	}
  
	render = () => {
		const columns = this.columns
		const showTotalFunction = (total, range) => {
			return `${range[0]}-${range[1]} of ${total} items`
		}

		const { products, actualPage, totalProducts, isLoading } = this.props
		const data = products || null

		return (
			<div>
				<Row type="flex" align="middle" style={{ marginBottom: '10px' }}>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							<Link to='/products/create'>Create product</Link>
						</Button>
					</Col>
					<Col span={8} offset={8}>
						<SearchProduct/>
					</Col>
				</Row>

				<Row style={{ marginBottom: '10px' }}>
					<Table {...this.state} pagination={{
						showTotal: showTotalFunction,
						current: actualPage,
						total: totalProducts,
						showSizeChanger: true,
						onShowSizeChange: this.handlePageSizeChange
					}} columns={columns} dataSource={data} rowKey="id" loading={isLoading} onChange={this.handleChange} />
				</Row>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		products: state.product.list.data,
		isLoading: state.product.list.isLoading,
		actualPage: state.product.list.actualPage,
		totalProducts: state.product.list.totalProducts,
		pageSize: state.product.list.pageSize,
		pageSizes: state.product.list.pageSizes
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getProducts: (pageValue, pageSizeValue, orderValue, searchValue) => {
			dispatch(getProducts(pageValue, pageSizeValue, orderValue, searchValue))
		},
		deleteProduct: (productID) => {
			dispatch(deleteProduct(productID))
		},
		duplicateProduct: (productID) => {
			dispatch(duplicateProduct(productID))
		}
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]),
	connect(mapStateToProps, mapDispatchToProps)
)(ProductList)
