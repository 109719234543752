import actionType from '../enums/actions'

const initState = {
	list: {
		data: [],
		actualPage: 1,
		totalProducts: 0,
		pageSize: 10,
		order: null,
		search: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {
			title: '',
			subtitle: '', 
			image: '',
			url: '',
			brand: '',
			applications: []
		},
		isLoading: false,
		isFailure: false
	}
}

const productReducer = (state = initState, action) => {
	switch (action.type) {
		case actionType.product.SET_LISTING_PARAMS: {
			return {
				...state,
				list: {
					...state.list,
					actualPage: action.data.page,
					pageSize: action.data.pageSize,
					order: action.data.order,
					search: action.data.search
				}
			}
		}
		case actionType.product.GET_PRODUCTS_REQUEST: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: true
				}
			
			}
		}
		case actionType.product.GET_PRODUCTS_SUCCESS: {
			return {
				...state,
				list: {
					...state.list,
					data: action.data.products,
					totalProducts: action.data.totalProducts,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.product.GET_PRODUCTS_ERROR: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.product.GET_PRODUCT_REQUEST: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true
				}
			}
		}
		case actionType.product.GET_PRODUCT_SUCCESS: {
			return {
				...state,
				detail: {
					...state.detail,
					data: action.product,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.product.GET_PRODUCT_ERROR: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.product.CREATE_PRODUCT_REQUEST: {
			return state
		}
		case actionType.product.CREATE_PRODUCT_SUCCESS: {
			return state
		}
		case actionType.product.CREATE_PRODUCT_ERROR: {
			return state
		}
		case actionType.product.UPDATE_PRODUCT_REQUEST: {
			return state
		}
		case actionType.product.UPDATE_PRODUCT_SUCCESS: {
			return state
		}
		case actionType.product.UPDATE_PRODUCT_ERROR: {
			return state
		}
		case actionType.product.DELETE_PRODUCT_REQUEST: {
			return state
		}
		case actionType.product.DELETE_PRODUCT_SUCCESS: {
			return state
		}
		case actionType.product.DELETE_PRODUCT_ERROR: {
			return state
		}
		case actionType.RESET_STATE: {
			return {
				...initState
			}
		}
		default:
			return state
	}
}

export default productReducer
