import React from 'react'
import { Field } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { Form, Input, Button } from 'antd'
import { createItem } from '../../atoms/formItems/AItem'
import  { required } from '../../enums/formValidations'

const FormItem = Form.Item

const AInput = createItem(Input)

class CreateUserForm extends React.Component {
	
	render = () => {
		const { submitText } = this.props
		const { handleSubmit, pristine, reset, submitting } = this.props
		const { t } = this.props

		const requiredField = { required: true, validate: [required] }
				
		return (
			<Form onSubmit={handleSubmit} >
				<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="email" label={t('components:email')} placeholder={t('components:email')} {...requiredField} component={AInput} />

				<FormItem >
					<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
						{t(`components:${submitText}`)}
					</Button>

					<Button disabled={pristine || submitting} onClick={reset}>
						{t('components:resetValues')}
					</Button>
				</FormItem>
			</Form>
		)
	}
}

export default withTranslation('components')(CreateUserForm)
