import React, { Component } from 'react'
import { reset } from 'redux-form'
import { Row, Col, message } from 'antd'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get, isArray, isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'
import { initialize } from 'redux-form'

import ProductLineForm from './ProductLineForm'
import { FORMS, ROLE_TYPE, LANGUAGE } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import errorHandler from '../../utils/errorHandler'
import { backendAxios } from '../../utils/axiosWrapper'
import { getIdsFromNames } from '../../utils/helper'

class CreateProductLine extends Component {

	handleSubmit = async (values) => {
		const { dispatch, applications, categories, brands, history } = this.props
		const hideLoading = message.loading('Creating in progress..', 0)

		try {

			// get applications ids
			const applicationIds = getIdsFromNames(values.applications, applications)

			// get category id
			let categoryId = getIdsFromNames(values.category, categories)

			// get brand id
			const brandId = getIdsFromNames(values.brand, brands)

			const thumbnail = get(values, 'thumbnail')
			let resultImage
			if(isArray(thumbnail)){
				resultImage = get(thumbnail, '[0].url')
			} else {
				resultImage = thumbnail
			}
			if(isArray(categoryId) && isEmpty(categoryId)) {
				categoryId = undefined
			}
			
			const productLineData = {
				applicationIds,
				categoryId: categoryId,
				brandId,
				thumbnail: resultImage,
				language: get(values, 'language'),
				name: get(values, 'name')
			}
			const { data } = await backendAxios.post('/productLines', productLineData)

			hideLoading()
			message.success(`Product line with ID: ${get(data, 'id')} was created`, 3)
			dispatch(reset(FORMS.PRODUCT_LINE))
			history.push(`/productLines/update/${get(data, 'id')}`)
			return
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Creating')
			return err
		}
	}

	componentDidMount = () => {
		const { dispatch } = this.props

		dispatch(reset(FORMS.PRODUCT_LINE))
		dispatch(initialize(FORMS.PRODUCT_LINE, {
			language: LANGUAGE.SK
		}))
	}

	render() {
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Create product line</h1>
					<ProductLineForm onSubmit={this.handleSubmit} submitText="create" />
				</Col>
			</Row>
		)
				
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch
	}
}
const mapStateToProps = (state) => {
	return ({
		applications: state.application.list.data,
		categories: state.category.list.data,
		brands: state.brand.list.data
	})

}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]),
	connect(mapStateToProps, mapDispatchToProps)
)(withRouter(CreateProductLine))
