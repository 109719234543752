import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import jwt from 'jsonwebtoken'
import { Row, Col } from 'antd'
import UpdateUserForm from './UpdateUserForm'
import { getUser } from '../../actions/userActions'
import { updateUserDetail } from '../../actions/authActions'
import { compose } from 'redux';
import { withPermissions } from '../../utils/permissionsHoc';
import { ROLE_TYPE } from '../../enums/enums';

// 
const ReduxFormUpdateUser = reduxForm({ form: 'updateUserDetail', enableReinitialize : true })(UpdateUserForm)

class UpdateUserDetail extends Component {

	componentDidMount() {
		const { token } = this.props
		const decodedToken = jwt.decode(token)

		this.props.getUser(decodedToken.id)
	}

	handleSubmit = (values) => {
		const { token } = this.props
		const decodedToken = jwt.decode(token)
		this.props.updateUserDetail(decodedToken.id, values)
	}

	render() {
		const { initialValues } = this.props

		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Update user</h1>
					<ReduxFormUpdateUser initialValues={initialValues} onSubmit={this.handleSubmit}
						disabledEmail={false} disabledRole={true} submitText="update" />
				</Col>
			</Row>
		)
				
	}
}

const mapStateToProps = (state) => {
	return {
		initialValues: state.user.detail.data,
		token: state.auth.accessToken
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUser: (userID) => {
			dispatch(getUser(userID))
		},
		updateUserDetail: (userID, values) => {
			dispatch(updateUserDetail(userID, values))
		}
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.EDITOR, ROLE_TYPE.MANAGER]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateUserDetail)
