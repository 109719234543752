import React, { Component } from 'react'
import { connect } from 'react-redux'
import { map, get, isArray } from 'lodash'
import { Row, Col, message } from 'antd'
import { compose, bindActionCreators } from 'redux'
import { initialize, reset } from 'redux-form'

import BannerForm from './BannerForm'
import * as BannerActions from '../../actions/bannerActions'
import { withPermissions } from '../../utils/permissionsHoc'
import { ROLE_TYPE, LANGUAGE, FORMS } from '../../enums/enums'
import { convertNullToUndefined, getIdsFromNames } from '../../utils/helper'
import errorHandler from '../../utils/errorHandler'
import { backendAxios } from '../../utils/axiosWrapper'

class UpdateBanner extends Component {

	initForm = async(language) => {
		const { computedMatch, bannerActions, dispatch } = this.props
		const { bannerID } = computedMatch.params
		const banner = await bannerActions.getBanner(bannerID, language)
		
		const brand = get(banner, 'brand.name')
		const assignedApps = map(get(banner, 'applications'), (application) => application.name)

		// set urlOrBrand by checking url existence
		const urlOrBrand = get(banner, 'url') ? false : true

		const initValue = { ...banner, urlOrBrand, brand, applications: assignedApps, language }
		dispatch(reset(FORMS.BANNER))
		dispatch(initialize(FORMS.BANNER, initValue))
	}

	componentDidMount() {
		this.initForm(LANGUAGE.SK)
	}

	handleSubmit = async (values) => {
		const { computedMatch, allApplications, allBrands, dispatch } = this.props
		const { bannerID } = computedMatch.params
		// this.props.updateBanner(bannerID, values)
		const hideLoading = message.loading('Updating in progress..', 0)
		try {
			// modified data for request (null->undefined)
			const payload = convertNullToUndefined(values)
			const { brand, applications, image } = payload

			// get applications ids
			
			const applicationIds = getIdsFromNames(applications, allApplications)

			// get brand id
			
			const brandId = getIdsFromNames(brand, allBrands)

			const resultImage = (isArray(image)) ? get(image, '[0].url') : image
			
			// final data for request
			const banner = {
				...payload, image: resultImage, brandId, applicationIds
			}

			if (values.urlOrBrand) {
				banner.url = undefined
			} else {
				banner.brandId = undefined
			}
			await backendAxios.put(`/banners/${bannerID}`, banner)
			
			hideLoading()
			message.success(`Banner with ID: ${get(values, 'id')} was updated`, 3)
			return
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Updating')
			return
		}
	}

	render() {
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Update banner</h1>
					<BannerForm onSubmit={this.handleSubmit} submitText="update" initForm={this.initForm} />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	allApplications: state.application.list.data,
	allBrands: state.brand.list.data
})
const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		bannerActions: bindActionCreators(BannerActions, dispatch)
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateBanner)
