import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { camelCase, get, debounce } from 'lodash'
import { Table, Button, Popconfirm, Divider, Row, Col, Input, message } from 'antd'
import { compose, bindActionCreators } from 'redux'

import * as ProductLinesActions from '../../actions/productLinesActions'
import { ROLE_TYPE } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import errorHandler from '../../utils/errorHandler';
import { backendAxios } from '../../utils/axiosWrapper';

class ProductLinesList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pagination: { position: 'none' },
			size: 'middle',
			showHeader: true
		}
	}

	columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			defaultSortOrder: 'ascend',
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Image',
			dataIndex: 'thumbnail',
			key: 'thumbnail',
			render: (text, productLine) => {
				if(get(productLine, 'thumbnail')) {
					return <img alt={get(productLine, 'name')} style={{display: 'block', maxWidth: '180px', maxHeight: '70px', width: 'auto', height: 'auto'}} src={get(productLine, 'thumbnail')}/>
				} else {
					return ''
				}
			}
		},{
			title: 'Category',
			dataIndex: 'category.name',
			key: 'categoriesName',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Brand',
			dataIndex: 'brand.name',
			key: 'brandsName',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Languages',
			dataIndex: 'languages',
			key: 'laguages',
			sorter: false
		}, {
			title: 'Action',
			key: 'action',
			render: (text, productLine) => {
				return (<span>
					<Link to={'/productLines/update/' + productLine.id}>Update</Link>
					<Divider type="vertical" />
					<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(productLine.id)}>
						<Link to={'#'}>Delete</Link>
					</Popconfirm>
				</span>)
			}
		}
	]

	componentDidMount() {
		this.props.productLinesActions.getProductLines(undefined, undefined, 'name:ascend', '')
	}

	handleDelete = async (productLineID) => {
		const hideLoading = message.loading('Deleting in progress..', 0)
		const { dispatch } = this.props
		try {
			
			const { data } = await backendAxios.delete(`/productLines/${productLineID}`)

			hideLoading()
			message.success(`Product line with ID: ${get(data, 'id')} was deleted`, 3)
			this.props.productLinesActions.getProductLines()
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Creating')
		}
	}

	handleSearch = (value) => {
		this.props.productLinesActions.getProductLines(undefined, undefined, undefined, value.search)
	}
	
	handlePageChange = (page) => {
		this.props.productLinesActions.getProductLines(page, undefined, undefined, undefined)
	}

	handlePageSizeChange = (current, pageSize) => {
		this.props.productLinesActions.getProductLines(current, pageSize, undefined, undefined)
	}

	handleChange = (pagination, filters, sorter) => {
		// get correct format of sort parameter
		// exp. categoryName:ascend
		const orderValue = `${camelCase(sorter.columnKey)}:${sorter.order}`

		if (sorter.field) {
			this.props.productLinesActions.getProductLines(pagination.current, pagination.pageSize, orderValue, undefined)
		} else {
			this.props.productLinesActions.getProductLines(pagination.current, pagination.pageSize, '', undefined)
		}
	}

	handleSearchChange = (e) => {
		const value = e.target.value
		this.debounceSearch(value)
	}
	  
	debounceSearch = debounce((search) => {
		this.props.productLinesActions.getProductLines(undefined, undefined, 'name:ascend', search)
	}, 300)
  
	render = () => {
		const columns = this.columns
		const showTotalFunction = (total, range) => {
			return `${range[0]}-${range[1]} of ${total} items`
		}

		const { productLines, actualPage, totalProductLines, isLoading } = this.props
		const data = productLines || null

		return (
			<div>
				<Row type="flex" align="middle" style={{ marginBottom: '10px' }}>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							<Link to='/productLines/create'>Create product line</Link>
						</Button>
					</Col>
					<Col span={8} offset={8}>
						<Input placeholder="Search" onChange={this.handleSearchChange} style={{float: "right"}}/>
					</Col>
				</Row>

				<Row style={{ marginBottom: '10px' }}>
					<Table {...this.state} pagination={{
						showTotal: showTotalFunction,
						current: actualPage,
						total: totalProductLines,
						showSizeChanger: true,
						onShowSizeChange: this.handlePageSizeChange
					}} columns={columns} dataSource={data} rowKey="id" loading={isLoading} onChange={this.handleChange} />
				</Row>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		productLines: state.productLine.list.data,
		isLoading: state.productLine.list.isLoading,
		actualPage: state.productLine.list.actualPage,
		totalProductLines: state.productLine.list.totalProductLines,
		pageSize: state.productLine.list.pageSize,
		pageSizes: state.productLine.list.pageSizes
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
		productLinesActions: bindActionCreators(ProductLinesActions, dispatch)
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]),
	connect(mapStateToProps, mapDispatchToProps)
)(ProductLinesList)
