import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { Form, Input, Checkbox, Button, Select, Tabs } from 'antd'
import { map } from 'lodash'

import UploadImage from '../../atoms/UploadImage'
import { createItem } from '../../atoms/formItems/AItem'
import { createCheckbox } from '../../atoms/formItems/ACheckbox'
import { createSelect } from '../../atoms/formItems/ASelect'
import  { required } from '../../enums/formValidations'
import { FORMS, LANGUAGES } from '../../enums/enums'

const FormItem = Form.Item
const { Option } = Select
const { TabPane } = Tabs

const AInput = createItem(Input)
const ATextArea = createItem(Input.TextArea)
const ACheckBox = createCheckbox(Checkbox)
const AUpload = createItem(UploadImage)
const ASelect = createSelect(Select)

class BrandForm extends React.Component {

	onChangeLanguage = (language) => {
		const { change, initForm, pristine, submit } = this.props
		if (pristine && initForm) {
			initForm(language)
		} else if (pristine) {
			change('language', language)
		} else if (!pristine && initForm){
			submit()
			initForm(language)
		} else {
			submit()
			change('language', language)
		}
	}

	render = () => {
		const { submitText, isPromoted, applications, language, t, handleSubmit, pristine, reset, submitting, initialized } = this.props

		const requiredField = { required: true, validate: [required] }

		const applicationOptions = map(applications, (application) => {
			const { id, name } = application
			return <Option value={name} key={id}>{name}</Option>
		})
				
		return (<Tabs onChange={this.onChangeLanguage} activeKey={language}>
			{LANGUAGES.map(pane => (
				<TabPane tab={pane} key={pane}>
					<Form onSubmit={handleSubmit} >
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="name" label={t('components:name')} placeholder={t('components:name')} {...requiredField} component={AInput} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="description" label={t('components:description')} placeholder={t('components:description')} component={ATextArea} />
			
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="logo" className="avatar-uploader" label="Logo" {...requiredField}
							width={250} height={166} showCropper={true} initialized={initialized} component={AUpload} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="isPromoted" label={t('components:promoted')} placeholder={t('components:promoted')}
							checked={isPromoted} component={ACheckBox} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="applications" label={t('components:applications')} placeholder={t('components:applicationsPlaceholder')}
							mode="multiple" component={ASelect} >
							{applicationOptions}
						</Field>
						
						<FormItem>
							<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
								{t(`components:${submitText}`)}
							</Button>

							<Button disabled={pristine || submitting} onClick={reset}>
							{t('components:resetValues')}
							</Button>
						</FormItem>
					</Form>
				</TabPane>))}
			</Tabs>
		)
	}
}

const selector = formValueSelector(FORMS.BRAND)

const mapStateToProps = (state) => {

	return {
		applications: state.application.list.data,
		language: selector(state, 'language'),
		// state of isPromoted field
		isPromoted: selector(state, "isPromoted")
	}
}

const form = reduxForm({
	form: FORMS.BRAND,
	destroyOnUnmount: true
})(BrandForm)

export default compose(
	connect(mapStateToProps),
	withTranslation('components')
)(form)
