import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, message } from 'antd'
import { compose } from 'redux'
import { reset, initialize } from 'redux-form'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'

import BrandForm from './BrandForm'
import { ROLE_TYPE, LANGUAGE, FORMS } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import { getIdsFromNames } from '../../utils/helper'
import { backendAxios } from '../../utils/axiosWrapper'
import errorHandler from '../../utils/errorHandler'

class CreateBrand extends Component {

	handleSubmit = async(values) => {
		// this.props.createBrand(values)
		const hideLoading = message.loading('Creating in progress..', 0)
		const { allApplications, dispatch, history } = this.props
		try {
			const { name, description, logo, isPromoted, applications } = values

			const resultImage = logo && logo[0].url

			// get applications ids
			const applicationIds = getIdsFromNames(applications, allApplications)

			const brand = {
				name, description, logo: resultImage, isPromoted, applicationIds, language: values.language
			}

			const { data } = await backendAxios.post('/brands', brand)
			
			hideLoading()
			message.success(`Brand with ID: ${get(data, 'id')} was created`, 3)
			
			dispatch(reset(FORMS.BR))
			history.push(`/brands/update/${get(data, 'id')}`)
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Creating')
		}
	}

	componentDidMount = () => {
		const { dispatch } = this.props

		dispatch(reset(FORMS.BRAND))
		dispatch(initialize(FORMS.BRAND, {
			urlOrBrand: false,
			language: LANGUAGE.SK
		}))
	}

	render() {

		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Create brand</h1>
					<BrandForm onSubmit={this.handleSubmit} submitText="create"/>
				</Col>
			</Row>
		)
				
	}
}

const mapStateToProps = (state) => {
	return {
		allApplications: state.application.list.data
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER]),
	connect(mapStateToProps, mapDispatchToProps)
)(withRouter(CreateBrand))
