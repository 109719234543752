import axios from 'axios'

let backendAxios
let awsS3Axios

export const initializeInstances = (store) => {
	backendAxios = axios.create({
		baseURL: '/api/web/v0/'
	})

	backendAxios.interceptors.request.use(function(config) {	
		const { accessToken, isLoggedIn } = store.getState().auth
		
		const authHader = isLoggedIn && { Authorization: `Bearer ${accessToken}` }
		const headers = { ...authHader, ...config.headers }
		const editedConfig = { ...config, headers }
	
		return editedConfig
	}, function(error) {
		return Promise.reject(error)
	})

	awsS3Axios = axios.create();

	// awsS3Axios = axios.create({
	// 	transformRequest: [
	// 		(data, headers) => {
	// 			delete headers.common.Authorization       
	// 			return data
	// 		}
	// 	]
	// })
}

export { backendAxios, awsS3Axios }
