import { message } from 'antd'
import { map, get, orderBy } from 'lodash'

import { backendAxios } from '../utils/axiosWrapper'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'
import { getCategoriesByAppId } from './categoryActions'

/**
 * @param {Integer} pageValue wanted page
 * @param {Integer} pageSizeValue wanted number of items on one page
 * @param {} orderValue wanted order
 * @param {String} searchValue wanted search value
 */
export const getProducts = (pageValue = 1, pageSizeValue, orderValue, searchValue) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.product.GET_PRODUCTS_REQUEST })
			const { actualPage, pageSize, order, search } = getState().product.list

			const pageResult = pageValue || actualPage
			const pageSizeResult = pageSizeValue || pageSize
			const orderResult = orderValue === '' ? null : orderValue || order
			const searchResult = searchValue === '' ? null : searchValue || search

			const products = await backendAxios.get('/products', {
				params: {
					limit: pageSizeResult,
					page: pageResult,
					order: orderResult,
					search: searchResult
				}
			})

			// store listing data
			dispatch({ type: actionType.product.GET_PRODUCTS_SUCCESS, data: { products: products.data.rows, totalProducts: products.data.count } })

			// store listing params
			dispatch({ type: actionType.product.SET_LISTING_PARAMS, data: { page:pageResult, pageSize: pageSizeResult, order: orderResult, search: searchResult }})
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.product.GET_PRODUCTS_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const getProduct = (productID, language) => {
	return async (dispatch) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.product.GET_PRODUCT_REQUEST })
			
			const product = await backendAxios.get(`/products/${productID}`, {
				headers: {
					'accept-language': language
				}
			})

			const appNames = map(product.data.applications, (application) => {
				return application.name
			})
			dispatch(getCategoriesByAppId(appNames))

			const variants = orderBy(get(product, 'data.variants'), ['default'], ['desc'])
			
			const data = {
				...get(product, 'data', {}),
				variants
			}

			dispatch({
				type: actionType.product.GET_PRODUCT_SUCCESS,
				product: data
			})
			
			hideLoading()
			return data
		} catch (err) {
			dispatch({ type: actionType.product.GET_PRODUCT_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Loading')
			return err
		}
	}
}

export const deleteProduct = (productID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Deleting in progress..', 0)
		try {
			dispatch({ type: actionType.product.DELETE_PRODUCT_REQUEST })

			const { actualPage, pageSize, totalProducts } = getState().product.list
			
			const product = await backendAxios.delete(`/products/${productID}`)

			dispatch({ type: actionType.product.DELETE_PRODUCT_SUCCESS, product: product.data })
			
			hideLoading()

			// after update is done, recalculate actual page and get all products
			const pageCheck = ((totalProducts - 1) / pageSize) + 1
			const pageResult = actualPage >= pageCheck ? (actualPage - 1) : actualPage

			dispatch(getProducts((pageResult, undefined, undefined, undefined)))
		} catch (err) {
			dispatch({ type: actionType.product.DELETE_PRODUCT_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Deleting')
		}
	}
}

export const duplicateProduct = (productID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Duplicating in progress..', 0)
		try {
			const { actualPage, pageSize, totalProducts } = getState().product.list

			await backendAxios.post(`/products/${productID}/duplicate`)

			hideLoading()

			// after update is done, recalculate actual page and get all products
			const pageCheck = ((totalProducts - 1) / pageSize) + 1
			const pageResult = actualPage >= pageCheck ? (actualPage - 1) : actualPage

			dispatch(getProducts((pageResult, undefined, undefined, undefined)))
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Duplicating')
		}
	}
}
