import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Row, Col } from 'antd'
import CreateUserForm from './CreateUserForm'
import { registerUser } from '../../actions/userActions'
import { FORMS, ROLE_TYPE } from '../../enums/enums'
import { compose } from 'redux';
import { withPermissions } from '../../utils/permissionsHoc';

const ReduxFormCreateUser = reduxForm({ form: FORMS.CREATE_USER })(CreateUserForm)

class CreateUser extends Component {

	handleSubmit = (values) => {
		const { email } = values
		this.props.registerUser(email)
	}

	render() {
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Create user</h1>
					<ReduxFormCreateUser onSubmit={this.handleSubmit} submitText="create" />
				</Col>
			</Row>
		)
				
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		registerUser: (email) => {
			dispatch(registerUser(email))
		}
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN]),
	connect(null, mapDispatchToProps)
)(CreateUser)
