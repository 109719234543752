import { message } from 'antd'

import { backendAxios } from '../utils/axiosWrapper'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'

/**
 * @param {Integer} pageValue wanted page
 * @param {Integer} pageSizeValue wanted number of items on one page
 * @param {} orderValue wanted order
 * @param {String} searchValue wanted search value
 */
export const getBanners = (pageValue = 1, pageSizeValue, orderValue, searchValue) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.banner.GET_BANNERS_REQUEST })
			const { actualPage, pageSize, order, search } = getState().banner.list

			const pageResult = pageValue || actualPage
			const pageSizeResult = pageSizeValue || pageSize
			const orderResult = orderValue === '' ? null : orderValue || order
			const searchResult = searchValue === '' ? null : searchValue || search

			const banners = await backendAxios.get('/banners', {
				params: {
					limit: pageSizeResult,
					page: pageResult,
					order: orderResult,
					search: searchResult
				}
			})

			// store listing data
			dispatch({ type: actionType.banner.GET_BANNERS_SUCCESS, data: { banners: banners.data.rows, totalBanners: banners.data.count } })

			// store listing params
			dispatch({ type: actionType.banner.SET_LISTING_PARAMS, data: { page: pageResult, pageSize: pageSizeResult, order: orderResult, search: searchResult }})
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.banner.GET_BANNERS_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const getBanner = (bannerID, language) => {
	return async (dispatch) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.banner.GET_BANNER_REQUEST })
			
			const banner = await backendAxios.get(`/banners/${bannerID}`, {
				headers: {
					'accept-language': language
				}
			})

			dispatch({ type: actionType.banner.GET_BANNER_SUCCESS, banner: banner.data })
			console.log(banner, 'SFE')
			hideLoading()
			return banner.data
		} catch (err) {
			dispatch({ type: actionType.banner.GET_BANNER_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Loading')
			return err
		}
	}
}

export const deleteBanner = (bannerID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Deleting in progress..', 0)
		try {
			dispatch({ type: actionType.banner.DELETE_BANNER_REQUEST })

			const { actualPage, pageSize, totalBanners } = getState().banner.list
			
			const banner = await backendAxios.delete(`/banners/${bannerID}`)

			dispatch({ type: actionType.banner.DELETE_BANNER_SUCCESS, banner: banner.data })
			
			hideLoading()

			// after update is done, recalculate actual page and get all banners
			const pageCheck = ((totalBanners - 1) / pageSize) + 1
			const pageResult = actualPage >= pageCheck ? (actualPage - 1) : actualPage

			dispatch(getBanners(pageResult, undefined, undefined, undefined))
		} catch (err) {
			dispatch({ type: actionType.banner.DELETE_BANNER_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Deleting')
		}
	}
}
