import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import jwt from 'jsonwebtoken'
import qs from 'qs'
import { has } from 'lodash'
import { message } from 'antd'

import { FORMS } from '../../enums/enums'
import RegisterForm from './RegisterForm'
import { register } from '../../actions/authActions'

const ReduxFormRegister = reduxForm({ form: FORMS.REGISTER })(RegisterForm)

class RegisterPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			token: null
		}
	}

	componentDidMount = () => {
		const { t } = this.props

		const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		if (has(queryParams, 'token')) {
			const decodedToken = jwt.decode(queryParams.token)

			if (decodedToken && decodedToken.aud === 'register' && decodedToken.exp > (Date.now() / 1000)) {
				this.setState({
					token: queryParams.token
				})
			} else {
				// invalid token -> redirect
				message.error(t('components:invalidToken'), 5)
				this.props.history.push('/login')
			}
		} else {
			// token is not provided -> redirect
			message.error(t('components:tokenNotProvided'), 5)
			this.props.history.push('/login')
		}
	}

	handleSubmit = (values) => {
		const { token } = this.state
		const { register, history } = this.props

		register(values, token, history)
	}

	render = () => {
		const { token } = this.state

		return (
			<ReduxFormRegister onSubmit={this.handleSubmit} token={token} />
		)
				
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		register: (values, token, history) => {
			dispatch(register(values, token, history))
		}
	}
}

export default compose(
	connect(null, mapDispatchToProps),
	withTranslation('components')
)(withRouter(RegisterPage))
