import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { logout } from '../../actions/authActions'

class LogoutButton extends Component {

	onLogoutClick = () => {
		this.props.logout(false)
		this.props.history.push('/login')
	}

	render() {
		return (
			<Button type="primary" style={{ marginRight: "16px", marginLeft: "10px" }} onClick={this.onLogoutClick}>
				Logout
			</Button>
		)
				
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		logout: (expiredJWT) => {
			dispatch(logout(expiredJWT))
		}
	}
}

export default connect(null, mapDispatchToProps)(withRouter(LogoutButton))
