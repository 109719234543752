import { reset } from 'redux-form'
import { message } from 'antd'
import jwt from 'jsonwebtoken'

import { backendAxios } from '../utils/axiosWrapper'
import { updateUser } from './userActions'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'
import { FORMS } from '../enums/enums'

export const login = ({ email, password }) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Login in progress..', 0)
		try {
			dispatch({ type: actionType.auth.LOGIN_REQUEST })
			const responseData = await backendAxios.post('/login', {
				email,
				password
			})
			
			const accessToken = responseData.data.token

			const decodedToken = jwt.decode(accessToken)


			const user = await backendAxios.get(`/users/${decodedToken.id}`, {
				headers: { Authorization: `Bearer ${accessToken}` }
			})

			dispatch({ type: actionType.auth.LOGIN_SUCCESS, accessToken, user: user.data })

			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.auth.LOGIN_ERROR, err: err.response.data.message })
			hideLoading()
		}
	}
}

/**
 * Logout function which clears user data.
 * Can be executed by user logout button click or by 401 unauthorized error handler.
 * @param {Boolean} expiredJWT 
 */
export const logout = (expiredJWT = false) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Logout in progress..', 0)
		try {
			if (expiredJWT) {
				dispatch({ type: actionType.auth.LOGOUT_EXPIRED_JWT })
			} else {
				dispatch({ type: actionType.auth.LOGOUT_USER_REQUEST })
			}
			dispatch({ type: actionType.RESET_STATE })

			dispatch(reset(FORMS.BANNER))
			dispatch(reset(FORMS.BRAND))
			dispatch(reset(FORMS.CATEGORY))
			dispatch(reset(FORMS.PRODUCT))
			dispatch(reset('createUser'))
			dispatch(reset('updateUser'))
			dispatch(reset('updateUserDetail'))

			hideLoading()
		} catch (err) {
			hideLoading()
			message.error('Logout failed')
		}
	}
}

export const resetForgotPasswordForm = () => {
	return async (dispatch, getState) => {
		dispatch(reset('forgotPassword'))
	}
}

export const resetChangePasswordForm = () => {
	return async (dispatch, getState) => {
		dispatch(reset('changePassword'))
	}
}

export const getUserDetail = (userID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.auth.GET_USER_DETAIL_REQUEST })
			
			const user = await backendAxios.get(`/users/${userID}`)

			dispatch({ type: actionType.auth.GET_USER_DETAIL_SUCCESS, user: user.data })
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.auth.GET_USER_DETAIL_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const updateUserDetail = (userID, data) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Updating in progress..', 0)
		try {
			dispatch({ type: actionType.auth.UPDATE_USER_DETAIL_REQUEST })

			dispatch(updateUser(userID, data))
			dispatch(getUserDetail(userID))

			dispatch({ type: actionType.auth.UPDATE_USER_DETAIL_REQUEST })
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.auth.UPDATE_USER_DETAIL_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Updating')
		}
	}
}

export const sendResetPassRequest = (email) => {
	return async (dispatch, getStage) => {
		const hideLoading = message.loading('Sending in progress..', 0)
		try {
			await backendAxios.post('/email-reset', {
				email
			})
			dispatch(reset('forgotPassword'))
			
			hideLoading()
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Sending')
		}
	}
}

export const changePassword = ({ password, confirmPassword, token }) => {
	return async (dispatch, getStage) => {
		const hideLoading = message.loading('changing in progress..', 0)
		try {
			await backendAxios.put('/email-reset', {
				password,
				confirmPassword
			}, {
				params: {
					token
				}
			})
			dispatch(reset('changePassword'))
			
			hideLoading()
			message.success('password succesfully changed')

			dispatch({ type: actionType.auth.CHANGE_PASSWORD_SUCCESS })
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Sending')
		}
	}
}

export const resetChangePasswordState = () => {
	return (dispatch, getState) => {
		dispatch({ type: actionType.auth.CHANGE_PASSWORD_RESET })
	}
}

export const register = (values, token, history) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('registration in progress..', 0)
		try {
			await backendAxios.put('/register', values, {
				params: {
					token
				}
			})
			dispatch(reset(FORMS.REGISTER))
			
			hideLoading()
			message.success('registration succesfully')

			dispatch({ type: actionType.auth.CHANGE_PASSWORD_SUCCESS })
			history.push('/login')
		} catch (err) {
			hideLoading()
			if(err.response) {
				const { data } = err.response
				const errorMessage = data.message
				message.error(`${errorMessage}`, 2.5)
			}
		}
	}
}
