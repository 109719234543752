import React, { Suspense } from 'react'
import { Switch, BrowserRouter as Router } from 'react-router-dom'

import { Spin, Form } from 'antd'

import MainLayout from '../components/layouts/MainLayout'
import AuthLayout from '../components/layouts/AuthLayout'
import NotFound from '../components/NotFound'

import BannerList from '../components/banners/BannerList'
import CreateBanner from '../components/banners/CreateBanner'
import UpdateBanner from '../components/banners/UpdateBanner'
import BrandList from '../components/brands/BrandList'
import CreateBrand from '../components/brands/CreateBrand'
import UpdateBrand from '../components/brands/UpdateBrand'
import CategoryList from '../components/categories/CategoryList'
import CreateCategory from '../components/categories/CreateCategory'
import UpdateCategory from '../components/categories/UpdateCategory'
import ProductList from '../components/products/ProductList'
import CreateProduct from '../components/products/CreateProduct'
import UpdateProduct from '../components/products/UpdateProduct'
import UserList from '../components/users/UserList'
import CreateUser from '../components/users/CreateUser'
import UpdateUser from '../components/users/UpdateUser'
import UserDetail from '../components/users/UserDetail'
import RegisterPage from '../components/auth/RegisterPage'

import LoginForm from '../components/auth/LoginForm'
import ForgotPassword from '../components/auth/ForgotPasswordPage'
import ChangePassword from '../components/auth/ChangePasswordPage'

import PrivateRoute from './PrivateRoute'
import Route from './Route'
import ForbiddenPage from '../components/ForbiddenPage'
import ProductLinesList from '../components/productLines/ProductLinesList'
import CreateProductLine from '../components/productLines/CreateProductLine'
import UpdateProductLine from '../components/productLines/UpdateProductLine'

const WrappedLoginForm = Form.create()(LoginForm)

class Routes extends React.Component {
	
	render = () => {
		return (
			<div id="body" style={{ height: '100%' }}>
				<Router>
					<Suspense fallback={<Spin size="large" />}>
						<Switch>
							<PrivateRoute exact path='/' breadcrumbName="Products" layout={MainLayout} component={ProductList} menuItem="products"/>

							<PrivateRoute exact path='/banners' breadcrumbName="Banners" layout={MainLayout} component={BannerList} menuItem="banners"/>
							<PrivateRoute exact path='/banners/create' breadcrumbName="Banners create" layout={MainLayout} component={CreateBanner} menuItem="banners"/>
							<PrivateRoute exact path='/banners/update/:bannerID' breadcrumbName="Banners:id" layout={MainLayout} component={UpdateBanner} menuItem="banners"/>

							<PrivateRoute exact path='/brands' breadcrumbName="Brands" layout={MainLayout} component={BrandList} menuItem="brands"/>
							<PrivateRoute exact path='/brands/create' breadcrumbName="Brands create" layout={MainLayout} component={CreateBrand} menuItem="brands"/>
							<PrivateRoute exact path='/brands/update/:brandID' breadcrumbName="Brands:id" layout={MainLayout} component={UpdateBrand} menuItem="brands"/>

							<PrivateRoute exact path='/categories' breadcrumbName="Categories" layout={MainLayout} component={CategoryList} menuItem="categories"/>
							<PrivateRoute exact path='/categories/create' breadcrumbName="Categories create" layout={MainLayout} component={CreateCategory} menuItem="categories"/>
							<PrivateRoute exact path='/categories/update/:categoryID' breadcrumbName="Categories:id" layout={MainLayout} component={UpdateCategory} menuItem="categories"/>

							<PrivateRoute exact path='/products' breadcrumbName="Products" layout={MainLayout} component={ProductList} menuItem="products"/>
							<PrivateRoute exact path='/products/create' breadcrumbName="Products create" layout={MainLayout} component={CreateProduct} menuItem="products"/>
							<PrivateRoute exact path='/products/update/:productID' breadcrumbName="Products:id" layout={MainLayout} component={UpdateProduct} menuItem="products" />

							<PrivateRoute exact path='/productLines' breadcrumbName="Product Lines" layout={MainLayout} component={ProductLinesList} menuItem="productLines"/>
							<PrivateRoute exact path='/productLines/create' breadcrumbName="Products Lines create" layout={MainLayout} component={CreateProductLine} menuItem="productLines"/>
							<PrivateRoute exact path='/productLines/update/:productLineID' breadcrumbName="Products Lines update" layout={MainLayout} component={UpdateProductLine} menuItem="productLines"/>

							<PrivateRoute exact path='/administration/users' breadcrumbName="Users" layout={MainLayout} component={UserList} openMenuItem="administration" menuItem="users"/>
							<PrivateRoute exact path='/administration/users/create' breadcrumbName="Users create" layout={MainLayout} component={CreateUser} openMenuItem="administration" menuItem="users"/>
							<PrivateRoute exact path='/administration/users/update/:userID' breadcrumbName="Users:id" layout={MainLayout} component={UpdateUser} openMenuItem="administration" menuItem="users"/>
							<PrivateRoute exact path='/userAccount' breadcrumbName=" Account" layout={MainLayout} component={UserDetail} />
							
							<Route exact path='/register' layout={AuthLayout} component={RegisterPage} />
							<Route exact path='/login' layout={AuthLayout} component={WrappedLoginForm} />
							<Route exact path='/forgot-password' layout={AuthLayout} component={ForgotPassword} />
							<Route exact path='/email-reset' layout={AuthLayout} component={ChangePassword} />
							<Route exact path='/403' component={ForbiddenPage} />
							<Route exact path='/404' component={NotFound} />

							<Route exact path='*' component={NotFound} />
						</Switch>
					</Suspense>
				</Router>
			</div>
		)
	}
}


export default Routes
