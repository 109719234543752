import { backendAxios } from '../utils/axiosWrapper'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'

export const getApplications = () => {
	return async (dispatch, getState) => {
		try {
			dispatch({ type: actionType.banner.GET_BANNERS_REQUEST })

			const applications = await backendAxios.get('/applications')
			
			dispatch({ type: actionType.application.GET_APPLICATIONS_SUCCESS, data: applications.data.rows })
		} catch (err) {
			dispatch({ type: actionType.application.GET_APPLICATIONS_ERROR })
			errorHandler(err, dispatch, undefined, 'Loading')
		}
	}
}
