import actionType from '../enums/actions'

const initState = {
	list: {
		data: [],
		actualPage: 1,
		totalBrands: 0,
		pageSize: 10,
		order: null,
		search: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {
			name: '',
			logo: '', 
			promoted: null
		},
		isLoading: false,
		isFailure: false
	}
}

const brandReducer = (state = initState, action) => {
	switch (action.type) {
		case actionType.brand.SET_LISTING_PARAMS: {
			return {
				...state,
				list: {
					...state.list,
					actualPage: action.data.page,
					pageSize: action.data.pageSize,
					order: action.data.order,
					search: action.data.search
				}
			}
		}
		case actionType.brand.GET_BRANDS_REQUEST: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: true
				}
			
			}
		}
		case actionType.brand.GET_BRANDS_SUCCESS: {
			return {
				...state,
				list: {
					...state.list,
					data: action.data.brands,
					totalBrands: action.data.totalBrands,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.brand.GET_BRANDS_ERROR: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.brand.GET_BRAND_REQUEST: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true
				}
			}
		}
		case actionType.brand.GET_BRAND_SUCCESS: {
			return {
				...state,
				detail: {
					...state.detail,
					data: action.brand,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.brand.GET_BRAND_ERROR: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.RESET_STATE: {
			return {
				...initState
			}
		}
		default:
			return state
	}
}

export default brandReducer
