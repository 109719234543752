import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import ForgotPasswordForm from './ForgotPasswordForm'
import { sendResetPassRequest } from '../../actions/authActions'

const ReduxFormForgotPassword = reduxForm({ form: 'forgotPassword' })(ForgotPasswordForm)

class ForgotPassword extends Component {

	handleSubmit = (values) => {
		const { sendResetPassRequest } = this.props
		sendResetPassRequest(values.email)
	}

	render() {

		return (
			<ReduxFormForgotPassword onSubmit={this.handleSubmit} />
		)
				
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		sendResetPassRequest: (email) => {
			dispatch(sendResetPassRequest(email))
		}
	}
}

export default connect(null, mapDispatchToProps)(ForgotPassword)
