import actionType from '../enums/actions'

const initState = {
	list: {
		data: [],
		actualPage: 1,
		totalCategories: 0,
		pageSize: 10,
		order: null,
		search: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {
			name: '',
			image: ''
		},
		isLoading: false,
		isFailure: false
	}
}

const categoryReducer = (state = initState, action) => {
	switch (action.type) {
		case actionType.category.SET_LISTING_PARAMS: {
			return {
				...state,
				list: {
					...state.list,
					actualPage: action.data.page,
					pageSize: action.data.pageSize,
					order: action.data.order,
					search: action.data.search
				}
			}
		}
		case actionType.category.GET_CATEGORIES_REQUEST: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: true
				}
			
			}
		}
		case actionType.category.GET_CATEGORIES_SUCCESS: {
			return {
				...state,
				list: {
					...state.list,
					data: action.data.categories,
					totalCategories: action.data.totalCategories,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.category.GET_CATEGORIES_ERROR: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.category.GET_CATEGORY_REQUEST: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true
				}
			}
		}
		case actionType.category.GET_CATEGORY_SUCCESS: {
			return {
				...state,
				detail: {
					...state.detail,
					data: action.category,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.category.GET_CATEGORY_ERROR: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.category.CREATE_CATEGORY_REQUEST: {
			return state
		}
		case actionType.category.CREATE_CATEGORY_SUCCESS: {
			return state
		}
		case actionType.category.CREATE_CATEGORY_ERROR: {
			return state
		}
		case actionType.category.UPDATE_CATEGORY_REQUEST: {
			return state
		}
		case actionType.category.UPDATE_CATEGORY_SUCCESS: {
			return state
		}
		case actionType.category.UPDATE_CATEGORY_ERROR: {
			return state
		}
		case actionType.category.DELETE_CATEGORY_REQUEST: {
			return state
		}
		case actionType.category.DELETE_CATEGORY_SUCCESS: {
			return state
		}
		case actionType.category.DELETE_CATEGORY_ERROR: {
			return state
		}
		case actionType.category.GET_CATEGORIES_BY_APP_ID_REQUEST: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: true
				}
			
			}
		}
		case actionType.category.GET_CATEGORIES_BY_APP_ID_SUCCESS: {
			return {
				...state,
				list: {
					...state.list,
					data: action.data.categories,
					totalCategories: action.data.totalCategories,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.category.GET_CATEGORIES_BY_APP_ID_ERROR: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.RESET_STATE: {
			return {
				...initState
			}
		}
		default:
			return state
	}
}

export default categoryReducer
