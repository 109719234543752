import React from 'react'
import { map, indexOf, filter, slice } from 'lodash'
import { Upload, Button, Icon, message } from 'antd'
import JSZip from 'jszip'

import { uploadFile } from '../utils/helper'

class MyUpload extends React.Component {
	
	handleBeforeUpload = (file, fileList) => {
		this.uploadFiles(fileList)

		return false
	}

	uploadFiles = async (fileList) => {
		const { value, onChange, fileCount, getData, uploadAsZip, fileName } = this.props
		const hide = message.loading(`file(s) uploading ...`, 0);
		// wanted number of files to upload
		const newFileCount = fileCount || 1

		// upload files
		const uploadFileList = map(fileList, async(file) => {
			const { name, type } = file
			let fileForUpload
			let fileForUploadType
			let fileForUploadName
			if(uploadAsZip) {
				const zip = new JSZip()
				const res = await new Promise((resolve, reject) => {
				const fileReader = new FileReader();
					fileReader.onload = function(){
						resolve(fileReader.result)
					}
					fileReader.readAsText(file)
				})
				zip.file(name, res)
				fileForUpload = await zip.generateAsync({
					type: "blob",
					compression: "DEFLATE",
					compressionOptions: {
						level: 9
					}
				})
				fileForUploadType = fileForUpload.type
				fileForUploadName = (fileName) ? this.addFileNameExtensionZip(fileName) :
					this.changeFileNameExtensionZip(name)
			} else {
				fileForUpload = file
				// get extension type if original is not set

				const checkedType = this.checkFileExtension(type, name)
				fileForUploadType = checkedType
				fileForUploadName = file.name
			}
			
			return uploadFile({ name: fileForUploadName, type: fileForUploadType, file: fileForUpload })

		})
		const uploadedFileList = await Promise.all(uploadFileList)

		// create file object ({ name, type, url })
		const uploadedFileObjectList = map(fileList, (file, index) => {
			const { uid, name, type } = file

			// change file name if file was changed to zip
			let resultFileName = name
			if (uploadAsZip) {
				resultFileName = (fileName) ? this.addFileNameExtensionZip(fileName) :
					this.changeFileNameExtensionZip(name)
			}

			// get extension type if original is not set
			const checkedType = this.checkFileExtension(type, name)

			return { uid: uid, name: resultFileName, type: checkedType, url: uploadedFileList[index] }
		})

		const joinedUploadList = [...value, ...uploadedFileObjectList]

		// check upload list size and slice it if needed
		const startIndexSlice = joinedUploadList.length > newFileCount ? joinedUploadList.length - newFileCount : 0
		const resizedUploadFileList = slice(joinedUploadList, startIndexSlice)

		// pass data to form component
		if (getData) {
			getData(fileList)
		}
		hide()
		message.success(`file(s) uploaded successfully`, 3);
		// change redux value
		onChange(resizedUploadFileList)
	}

	handleRemove = (file) => {
		const { value, onChange } = this.props

		const fileIndex = indexOf(value, file)
		const newFileList = filter(value, (file, index) => index !== fileIndex)

		onChange(newFileList)
	}

	checkFileExtension = (type, fileName) => {
		if(!type) {
			const extensionStartIndex = fileName.lastIndexOf('.')
			const extension = fileName.substring(extensionStartIndex + 1)
			return extension
		} else {
			return type
		}
	}

	changeFileNameExtensionZip = (fileName) => {
		return `${fileName.substr(0, fileName.lastIndexOf('.'))}.zip`
	}

	addFileNameExtensionZip = (fileName) => {
		return `${fileName}.zip`
	}

	render = () => {
		const { value } = this.props

		return (
			<Upload beforeUpload={this.handleBeforeUpload} onRemove={this.handleRemove} fileList={value}>
				<Button>
					<Icon type="upload" /> Select File
				</Button>
			</Upload>
		)
	}
}

export default MyUpload
