import React, { Component } from 'react'
import { connect } from 'react-redux'
import { initialize, SubmissionError, reset } from 'redux-form'
import { Row, Col, message } from 'antd'
import { isEmpty, uniqBy, isArray, get } from 'lodash'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

import ProductForm from './ProductForm'
import { FORMS, ROLE_TYPE, LANGUAGE } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import errorHandler from '../../utils/errorHandler'
import { backendAxios } from '../../utils/axiosWrapper'
import { formatProductData } from '../../utils/helper'

class CreateProduct extends Component {

	handleSubmit = async(values) => {
		const {
			categories,
			dispatch,
			applications,
			brands,
			productLines,
			history
		} = this.props

		const uniqueCategories = uniqBy(categories, 'name')

		if(isEmpty(values.category) && !isEmpty(uniqueCategories)) {
			throw new SubmissionError({
				category: 'Required'
			})
		} 

		const hideLoading = message.loading('Creating in progress..', 0)
		
		try {

			const resultProduct = formatProductData(values, applications, categories, brands, productLines)	
			let categoryId
			if(isArray(get(resultProduct, 'categoryId')) && isEmpty(get(resultProduct, 'categoryId'))) {
				categoryId = undefined
			} else {
				categoryId = get(resultProduct, 'categoryId')
			}

			const productData = {
				...resultProduct,
				categoryId
			}
			const { data } = await backendAxios.post('/products', productData)
			
			hideLoading()
			message.success(`Product with ID: ${get(data, 'id')} was created`, 3)
			dispatch(reset(FORMS.PRODUCT))
			history.push(`/products/update/${get(data, 'id')}`)
			return
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Creating')
			return err
		}
	}

	componentDidMount = () => {
		const { dispatch } = this.props

		dispatch(reset(FORMS.PRODUCT))
		dispatch(initialize(FORMS.PRODUCT, {
			language: LANGUAGE.SK
		}))
	}

	render = () => {

		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Create product</h1>
					<ProductForm 
						onSubmit={this.handleSubmit}
						submitText="create"
						handleGetCategoriesByAppId={this.handleGetCategoriesByAppId} />
				</Col>
			</Row>
		)
				
	}
}

const mapStateToProps = (state) => {
	return {
		applications: state.application.list.data,
		brands: state.brand.list.data,
		categories: state.category.list.data,
		productLines: state.productLine.list.data
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]),
	connect(mapStateToProps, mapDispatchToProps)
)(withRouter(CreateProduct))
