import { map, filter } from 'lodash'

const materialRegExp = /usemtl (.*)/g;

function readFileAsText(file) {
	return new Promise((resolve, reject) => {
		var reader = new FileReader()
		reader.onload = function() {
			return resolve(reader.result)
		}
		reader.onerror = function(e) {
			return reject(e)
		}
		reader.readAsText(file)
	})
}

export async function getMaterialsFromFile(file) {
	try {
		const fileContent = await readFileAsText(file)
		const foundMaterials = fileContent.match(materialRegExp)
		const editedMaterials = map(foundMaterials, (item) => {
			return item.substr('usemtl'.length + 1)
		})

		//only unique
		const filteredMaterials = filter(editedMaterials, (item, index, self) => {
			return self.indexOf(item) === index
		})

		return filteredMaterials
	} catch(err) {
		return null
	}
}
