import actionType from '../enums/actions'

const initState = {
	list: {
		data: [],
		isLoading: false,
		isFailure: false
	}
}

const applicationReducer = (state = initState, action) => {
	switch (action.type) {
		case actionType.application.GET_APPLICATIONS_REQUEST:
			return {
				list: {
					...state,
					isLoading: true
				}
			}
		case actionType.application.GET_APPLICATIONS_SUCCESS:
			return {
				list: {
					...state.list,
					data: action.data,
					isLoading: false,
					isFailure: true
				}
			}
		case actionType.application.GET_APPLICATIONS_ERROR:
			return {
				list: {
					...state.list,
					isLoading: false,
					isFailure: false
				}
			}
		case actionType.RESET_STATE: {
			return {
				...initState
			}
		}
		default:
			return state
	}
	
}

export default applicationReducer
