import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { camelCase, get } from 'lodash'
import { Table, Button, Popconfirm, Divider, Row, Col } from 'antd'

import SearchCategory from './SearchCategory'
import { getCategories, deleteCategory } from '../../actions/categoryActions'
import { ROLE_TYPE } from '../../enums/enums';
import { compose } from 'redux';
import { withPermissions } from '../../utils/permissionsHoc';

class CategoryList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pagination: { position: 'none' },
			size: 'middle',
			showHeader: true
		}
	}

	columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			defaultSortOrder: 'ascend',
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Image',
			dataIndex: 'image',
			key: 'image',
			render: (text, category) => {
				if(get(category, 'image')){
					return <img alt={get(category, 'name')} style={{display: 'block', maxWidth: '180px', maxHeight: '70px', width: 'auto', height: 'auto'}} src={get(category, 'image')}/>
				} else {
					return ''
				}
			}
		}, {
			title: 'Languages',
			dataIndex: 'languages',
			key: 'laguages',
			sorter: false
		}, {
			title: 'Action',
			key: 'action',
			render: (text, category) => {
				return this.props.totalCategories >= 1
					? (
						<span>
							<Link to={'/categories/update/' + category.id}>Update</Link>
							<Divider type="vertical" />
							<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(category.id)}>
								<Link to={'#'}>Delete</Link>
							</Popconfirm>
						</span>
					) : null
			}
		}
	]

	componentDidMount() {
		this.props.getCategories(undefined, undefined, 'name:ascend', '')
	}

	handleDelete = (categoryID) => {
		this.props.deleteCategory(categoryID)
	}

	handleSearch = (value) => {
		this.props.getCategories(undefined, undefined, undefined, value.search)
	}
	
	handlePageChange = (page) => {
		this.props.getCategories(page, undefined, undefined, undefined)
	}

	handlePageSizeChange = (current, pageSize) => {
		this.props.getCategories(current, pageSize, undefined, undefined)
	}

	handleChange = (pagination, filters, sorter) => {
		// get correct format of sort parameter
		// exp. categoryName:ascend
		const orderValue = `${camelCase(sorter.field)}:${sorter.order}`

		if (sorter.field) {
			this.props.getCategories(pagination.current, pagination.pageSize, orderValue, undefined)
		} else {
			this.props.getCategories(pagination.current, pagination.pageSize, '', undefined)
		}
	}
  
	render() {
		const columns = this.columns
		const showTotalFunction = (total, range) => {
			return `${range[0]}-${range[1]} of ${total} items`
		}

		const { categories, actualPage, totalCategories, isLoading } = this.props
		const data = categories || null

		return (
			<div>
				<Row type="flex" align="middle" style={{ marginBottom: '10px' }}>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							<Link to='/categories/create'>Create category</Link>
						</Button>
					</Col>
					<Col span={8} offset={8}>
						<SearchCategory/>
					</Col>
				</Row>

				<Row style={{ marginBottom: '10px' }}>
					<Table {...this.state} pagination={{
						showTotal: showTotalFunction,
						current: actualPage,
						total: totalCategories,
						showSizeChanger: true,
						onShowSizeChange: this.handlePageSizeChange
					}} columns={columns} dataSource={data} rowKey="id" loading={isLoading} onChange={this.handleChange} />
				</Row>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		categories: state.category.list.data,
		isLoading: state.category.list.isLoading,
		actualPage: state.category.list.actualPage,
		totalCategories: state.category.list.totalCategories,
		pageSize: state.category.list.pageSize,
		pageSizes: state.category.list.pageSizes
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getCategories: (pageValue, pageSizeValue, orderValue, searchValue) => {
			dispatch(getCategories(pageValue, pageSizeValue, orderValue, searchValue))
		},
		deleteCategory: (categoryID) => {
			dispatch(deleteCategory(categoryID))
		}
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN]),
	connect(mapStateToProps, mapDispatchToProps)
)(CategoryList)
