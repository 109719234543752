import React from 'react'
import { compose } from 'redux'
import { Field } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { Form, Input, Button } from 'antd'
import { createItem } from '../../atoms/formItems/AItem'
import  { required, passwordsMatch } from '../../enums/formValidations'

const FormItem = Form.Item

const AInput = createItem(Input)

class RegisterForm extends React.Component {
	componentDidMount = () => {
		const { reset } = this.props
		reset()
	}

	render = () => {
		const { handleSubmit, pristine, submitting } = this.props
		const { t } = this.props
		
		return (
			<Form onSubmit={handleSubmit} className="form-border">
				<div style={{textAlign: 'center'}}>
					<h2 style={{color: 'white'}}>{t('components:register')}</h2>
				</div>

				<Field name="firstName" placeholder={t('components:firstName')} component={AInput} 
					type='text' required validate={[required]} wrapperCol={{span: 24}} />

				<Field name="lastName" placeholder={t('components:lastName')} component={AInput} 
					type='text' required validate={[required]} wrapperCol={{span: 24}} />

				<Field name="password" placeholder={t('components:passwordPlaceholder')} component={AInput} 
					type='password' required validate={[required]} wrapperCol={{span: 24}} />
				
				<Field name="confirmPassword" placeholder={t('components:confirmPasswordPlaceholder')} component={AInput} 
					type='password' required validate={[required, passwordsMatch]} wrapperCol={{span: 24}} />

				<FormItem wrapperCol={{span: 24}}>
					<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
						{t('components:register')}
					</Button>
				</FormItem>
			</Form>
		)
	}
}


export default compose(
	withTranslation('components')
)(RegisterForm)
