import React, { Component } from 'react'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Input } from 'antd'
import { getBrands } from '../../actions/brandActions'

class SearchBrand extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: null
		}
	}

	handleChange = (e) => {
		const value = e.target.value
		this.debounceSearch(value)
	}
	  
	debounceSearch = debounce((search) => {
		this.props.getBrands(undefined, undefined, undefined, search)
	}, 300)
	
	render() {
		return (
			<Input placeholder="Search" onChange={this.handleChange} style={{float: "right"}}/>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getBrands: (page, pageSize, sort, search) => {
			dispatch(getBrands(page, pageSize, sort, search))
		}
	}
}

export default connect(null, mapDispatchToProps)(SearchBrand)
