import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, message } from 'antd'
import { compose } from 'redux';
import { reset, initialize } from 'redux-form'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'

import BannerForm from './BannerForm'
import { ROLE_TYPE, FORMS, LANGUAGE } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import errorHandler from '../../utils/errorHandler'
import { getIdsFromNames } from '../../utils/helper'
import { backendAxios } from '../../utils/axiosWrapper'

class CreateBanner extends Component {

	handleSubmit = async(values) => {
		const { dispatch, allApplications, allBrands, history } = this.props
		const hideLoading = message.loading('Creating in progress..', 0)

		try {
			// get applications ids
			const applicationIds = getIdsFromNames(get(values, 'applications'), allApplications)
			// get brand id
			const brandId = getIdsFromNames(get(values, 'brand'), allBrands)
			const resultImage = get(values, 'image[0].url')

			const banner = {
				title: get(values, 'title'),
				subtitle: get(values, 'subtitle'),
				image: resultImage,
				url: get(values, 'url'),
				brandId,
				applicationIds,
				language: get(values, 'language')
			}

			if (get(values, 'urlOrBrand')) {
				banner.url = undefined
			} else {
				banner.brandId = undefined
			}

			const { data } = await backendAxios.post('/banners', banner)

			dispatch(reset(FORMS.BANNER))
			hideLoading()
			message.success(`Banner with ID ${get(data, 'id')} was created`, 3)
			history.push(`/banners/update/${get(data, 'id')}`)
			return
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Creating')
			return err
		}
	}

	componentDidMount = () => {
		const { dispatch } = this.props

		dispatch(reset(FORMS.BANNER))
		dispatch(initialize(FORMS.BANNER, {
			urlOrBrand: false,
			language: LANGUAGE.SK
		}))
	}

	render = () => {
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Create banner</h1>
					<BannerForm onSubmit={this.handleSubmit} submitText="create" />
				</Col>
			</Row>
		)
				
	}
}

const mapStateToProps = (state) => ({
	allApplications: state.application.list.data,
	allBrands: state.brand.list.data
})

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER]),
	connect(mapStateToProps, mapDispatchToProps)
)(withRouter(CreateBanner))
