import React from 'react'
import { Redirect } from 'react-router-dom'
import { message } from 'antd'
import { logout } from '../actions/authActions'

/**
 * 
 * @param {Object} error 
 * @param {Function} dispatch
 * @param {Function} hideLoading 
 * @param {String} actionMessage
 */
export default function errorHandler(error, dispatch, hideLoading, actionMessage) {
	let showMessage = true
	let errorMessage

	if (hideLoading) {
		hideLoading()
	}

	if (error.response) {
		const { status, data } = error.response
		errorMessage = data.message
		
		if (status === 400) {
			console.log('bad request: ', errorMessage)
		} else if (status === 401) {
			showMessage = false

			dispatch(logout(true))
		} else if (status === 403) {
			console.log('forbiden: ', errorMessage)
		} else if (status === 404) {
			return <Redirect to='/not-found' />
		} else {
			console.log('unknown error: ', errorMessage)
		}
	} else if (error.request) {
		console.log('server is not responding: ', error.request)
	} else {
		console.log('something else: ', error)
	}

	
	
	if (errorMessage && showMessage) {
		message.error(`${actionMessage} failed- ${errorMessage}`, 2.5)
	} else {
		message.error(`${actionMessage}`, 2.5)
	}
}
