
export default {
	application: {
		GET_APPLICATIONS_REQUEST: 'GET_APPLICATIONS_REQUEST',
		GET_APPLICATIONS_SUCCESS: 'GET_APPLICATIONS_SUCCESS',
		GET_APPLICATIONS_ERROR: 'GET_APPLICATIONS_ERROR',
	},
	auth: {
		LOGIN_REQUEST: 'LOGIN_REQUEST',
		LOGIN_SUCCESS: 'LOGIN_SUCCESS',
		LOGIN_ERROR: 'LOGIN_ERROR',
		LOGOUT_USER_REQUEST: 'LOGOUT_REQUEST',		// logout by user
		LOGOUT_EXPIRED_JWT: 'LOGOUT_INVALID_JWT',	// logout by expired token
		GET_USER_DETAIL_REQUEST: 'GET_USER_DETAIL_REQUEST',
		GET_USER_DETAIL_SUCCESS: 'GET_USER_DETAIL_SUCCESS',
		GET_USER_DETAIL_ERROR: 'GET_USER_DETAIL_ERROR',
		UPDATE_USER_DETAIL_REQUEST: 'UPDATE_USER_DETAIL_REQUEST',
		UPDATE_USER_DETAIL_SUCCESS: 'UPDATE_USER_DETAIL_SUCCESS',
		UPDATE_USER_DETAIL_ERROR: 'UPDATE_USER_DETAIL_ERROR',
		CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
		CHANGE_PASSWORD_RESET: 'CHANGE_PASSWORD_RESET',
		LOGIN_ERROR_CLEAR: 'LOGIN_ERROR_CLEAR'
	},
	banner: {
		GET_BANNERS_REQUEST: 'GET_BANNERS_REQUEST',
		GET_BANNERS_SUCCESS: 'GET_BANNERS_SUCCESS',
		GET_BANNERS_ERROR: 'GET_BANNERS_ERROR',
		GET_BANNER_REQUEST: 'GET_BANNER_REQUEST',
		GET_BANNER_SUCCESS: 'GET_BANNER_SUCCESS',
		GET_BANNER_ERROR: 'GET_BANNER_ERROR',
		CREATE_BANNER_REQUEST: 'CREATE_BANNER_REQUEST',
		CREATE_BANNER_SUCCESS: 'CREATE_BANNER_SUCCESS',
		CREATE_BANNER_ERROR: 'CREATE_BANNER_ERROR',
		UPDATE_BANNER_REQUEST: 'UPDATE_BANNER_REQUEST',
		UPDATE_BANNER_SUCCESS: 'UPDATE_BANNER_SUCCESS',
		UPDATE_BANNER_ERROR: 'UPDATE_BANNER_ERROR',
		DELETE_BANNER_REQUEST: 'DELETE_BANNER_REQUEST',
		DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
		DELETE_BANNER_ERROR: 'DELETE_BANNER_ERROR',
		SET_LISTING_PARAMS: 'SET_LISTING_PARAMS'
	},
	brand: {
		GET_BRANDS_REQUEST: 'GET_BRANDS_REQUEST',
		GET_BRANDS_SUCCESS: 'GET_BRANDS_SUCCESS',
		GET_BRANDS_ERROR: 'GET_BRANDS_ERROR',
		GET_BRAND_REQUEST: 'GET_BRAND_REQUEST',
		GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
		GET_BRAND_ERROR: 'GET_BRAND_ERROR',
		CREATE_BRAND_REQUEST: 'CREATE_BRAND_REQUEST',
		CREATE_BRAND_SUCCESS: 'CREATE_BRAND_SUCCESS',
		CREATE_BRAND_ERROR: 'CREATE_BRAND_ERROR',
		UPDATE_BRAND_REQUEST: 'UPDATE_BRAND_REQUEST',
		UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
		UPDATE_BRAND_ERROR: 'UPDATE_BRAND_ERROR',
		DELETE_BRAND_REQUEST: 'DELETE_BRAND_REQUEST',
		DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
		DELETE_BRAND_ERROR: 'DELETE_BRAND_ERROR',
		SET_LISTING_PARAMS: 'SET_LISTING_PARAMS'
	},
	category: {
		GET_CATEGORIES_REQUEST: 'GET_CATEGORIES_REQUEST',
		GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
		GET_CATEGORIES_ERROR: 'GET_CATEGORIES_ERROR',
		GET_CATEGORY_REQUEST: 'GET_CATEGORY_REQUEST',
		GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
		GET_CATEGORY_ERROR: 'GET_CATEGORY_ERROR',
		CREATE_CATEGORY_REQUEST: 'CREATE_CATEGORY_REQUEST',
		CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
		CREATE_CATEGORY_ERROR: 'CREATE_CATEGORY_ERROR',
		UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
		UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
		UPDATE_CATEGORY_ERROR: 'UPDATE_CATEGORY_ERROR',
		DELETE_CATEGORY_REQUEST: 'DELETE_CATEGORY_REQUEST',
		DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
		DELETE_CATEGORY_ERROR: 'DELETE_CATEGORY_ERROR',
		GET_CATEGORIES_BY_APP_ID_REQUEST: 'GET_CATEGORIES_BY_APP_ID_REQUEST',
		GET_CATEGORIES_BY_APP_ID_SUCCESS: 'GET_CATEGORIES_BY_APP_ID_SUCCESS',
		GET_CATEGORIES_BY_APP_ID_ERROR: 'GET_CATEGORIES_BY_APP_ID_ERROR',
		SET_LISTING_PARAMS: 'SET_LISTING_PARAMS'
	},
	product: {
		GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
		GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
		GET_PRODUCTS_ERROR: 'GET_PRODUCTS_ERROR',
		GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
		GET_PRODUCT_SUCCESS: 'GET_PRODUCT_SUCCESS',
		GET_PRODUCT_ERROR: 'GET_PRODUCT_ERROR',
		CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
		CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
		CREATE_PRODUCT_ERROR: 'CREATE_PRODUCT_ERROR',
		UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
		UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
		UPDATE_PRODUCT_ERROR: 'UPDATE_PRODUCT_ERROR',
		DELETE_PRODUCT_REQUEST: 'DELETE_PRODUCT_REQUEST',
		DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
		DELETE_PRODUCT_ERROR: 'DELETE_PRODUCT_ERROR',

		SET_LISTING_PARAMS: 'SET_LISTING_PARAMS'
	},
	productLines: {
		GET_PRODUCT_LINES_REQUEST: 'GET_PRODUCT_LINES_REQUEST',
		GET_PRODUCT_LINES_SUCCESS: 'GET_PRODUCT_LINES_SUCCESS',
		GET_PRODUCT_LINES_ERROR: 'GET_PRODUCT_LINES_ERROR',
		GET_PRODUCT_LINE_REQUEST: 'GET_PRODUCT_LINE_REQUEST',
		GET_PRODUCT_LINE_SUCCESS: 'GET_PRODUCT_LINE_SUCCESS',
		GET_PRODUCT_LINE_ERROR: 'GET_PRODUCT_LINE_ERROR'
	},
	user: {
		GET_USERS_REQUEST: 'GET_USERS_REQUEST',
		GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
		GET_USERS_ERROR: 'GET_USERS_ERROR',
		GET_USER_REQUEST: 'GET_USER_REQUEST',
		GET_USER_SUCCESS: 'GET_USER_SUCCESS',
		GET_USER_ERROR: 'GET_USER_ERROR',
		REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
		REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
		REGISTER_USER_ERROR: 'REGISTER_USER_ERROR',
		CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
		CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
		CREATE_USER_ERROR: 'CREATE_USER_ERROR',
		UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
		UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
		UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
		DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
		DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
		DELETE_USER_ERROR: 'DELETE_USER_ERROR',
		SET_LISTING_PARAMS: 'SET_LISTING_PARAMS'
	},
	RESET_STATE: 'RESET_STATE'
}
