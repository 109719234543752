import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Row, Col } from 'antd'
import UpdateUserForm from './UpdateUserForm'
import { updateUser, getUser } from '../../actions/userActions'
import { compose } from 'redux';
import { withPermissions } from '../../utils/permissionsHoc';
import { ROLE_TYPE } from '../../enums/enums';

// 
const ReduxFormUpdateUser = reduxForm({ form: 'updateUser', enableReinitialize : true })(UpdateUserForm)

class UpdateUser extends Component {

	componentDidMount() {
		const { userID } = this.props.computedMatch.params
		this.props.getUser(userID)
	}

	handleSubmit = (values) => {
		const { userID } = this.props.computedMatch.params
		this.props.updateUser(userID, values)
	}

	render() {
		const { initialValues } = this.props

		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Update user</h1>
					<ReduxFormUpdateUser initialValues={initialValues} onSubmit={this.handleSubmit}
						disabledEmail={true} disabledRole={false} submitText="update" />
				</Col>
			</Row>
		)
				
	}
}

const mapStateToProps = (state) => {
	return {
		initialValues: state.user.detail.data
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		updateUser: (userID, values) => {
			dispatch(updateUser(userID, values))
		},
		getUser: (userID) => {
			dispatch(getUser(userID))
		}
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateUser)
