import React from 'react'
import { get, isString } from 'lodash'

export const createColorPicker = Component => (props) => {
	
	let value
	if(isString(get(props, 'input.value'))) {
		value = {
			hex: get(props, 'input.value') || '#000000'
		}
	} else {
		value = get(props, 'input.value', {}) || {}

	}
	return <Component disableAlpha color={value} onChangeComplete={props.input.onChange} />
}
