import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Icon } from 'antd'
import { map, filter, isEmpty, forEach } from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash'

import { checkPermissions } from '../../utils/permissionsHoc'
import { ROLE_TYPE } from '../../enums/enums'

const SubMenu = Menu.SubMenu

const menuItems = () => ({
	administration: {
		submenu: [{
			key: 'users',
			path: 'administration/users',
			title: 'Users',
			allowRoles: [ROLE_TYPE.ADMIN]
		}],
		title: 'Administration',
		key: 'administration',
		icon: 'user',
		allowRoles: [ROLE_TYPE.ADMIN]
	},
	products: {
		key: 'products',
		path: 'products',
		title: 'Products',
		icon: 'file',
		allowRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]
	},
	productLines: {
		key: 'productLines',
		path: 'productLines',
		title: 'ProductLines',
		icon: 'file',
		allowRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]
	},
	banners: {
		key: 'banners',
		path: 'banners',
		title: 'Banners',
		icon: 'pie-chart',
		allowRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER]
	},
	brands: {
		key: 'brands',
		path: 'brands',
		title: 'Brands',
		icon: 'desktop',
		allowRoles: [ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER]
	},
	categories: {
		key: 'categories',
		path: 'categories',
		title: 'Categories',
		icon: 'ordered-list',
		allowRoles: [ROLE_TYPE.ADMIN]
	}
})

class SideMenu extends React.Component {
	selectedItems = () => {
		const { menuItem } = this.props
		const items = menuItems()
		const keys = []
		forEach(items, (item) => {
			if(item.key === menuItem) {
				keys.push(item.key)
			} else if(item.submenu) {
				forEach(item.submenu, (submenu) => {
					if(submenu.key === menuItem) {
						keys.push(submenu.key)
					}
				})
			}
		})
		return keys
	}

	openedKeys = () => {
		const { openMenuItem } = this.props
		const items = menuItems()
		const filteredItems = filter(items, (item) => item.key === openMenuItem)
		return map(filteredItems, (filteredItem) => filteredItem.key)
	}

	render = () => {
		const { user } = this.props
		
		const roles = [get(user, 'role')]

		const selectedItem = this.selectedItems()
		const openKeys = this.openedKeys()
		const itemsMenu = map(menuItems(), (item) => {
			if (checkPermissions(roles, item.allowRoles)) {
				if(!isEmpty(item.submenu)) {
					const subItems = map(item.submenu, (submenu) => {
						return (
							<Menu.Item key={submenu.key}>
								<Link to={`/${submenu.path}`}>{submenu.title}</Link>
							</Menu.Item>
						)
					})
					return (
						<SubMenu key={item.key}
							title={<><Icon type={item.icon} /><span>{item.title}</span></>}>
							{subItems}
						</SubMenu>
					)
				} 
				return (
					<Menu.Item key={item.key} >
						<Icon type={item.icon} />
						<span>{item.title}</span>
						<Link to={`/${item.path}`} />
					</Menu.Item>
				)
			} else {
				return null
			}
		})
		return (
			<Menu theme="dark" defaultSelectedKeys={['products']}
				// openKeys={openKeys} // TODO: doriesit permanentne otvorenie sub menu na refresh
				selectedKeys={selectedItem}
				mode="inline">
				{itemsMenu}
			</Menu>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.auth.userDetail
	}
}

export default compose(
	connect(mapStateToProps)
)(withRouter(SideMenu))
