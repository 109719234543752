import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
	Form, Icon, Input, Button, Alert
} from 'antd'

import actionType from '../../enums/actions'
import { login } from '../../actions/authActions'

class LoginForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			confirmDirty: false,
			email: '',
			password: ''
		}
	}

	handleSubmit = (e) => {
		e.preventDefault()
		this.props.form.validateFields((err, values) => {
			if (!err) {
				const { email, password } = this.state
				this.props.login({ email, password })
			}	
		})
	}

	handleChange = (e) => {
		this.setState({
			[e.target.id]: e.target.value
		})
	}

	componentDidMount = () => {
		const { errorClear } = this.props
		errorClear()
	}
	
	render = () => {
		const { isLoggedIn, isFailure, tokenIsExpired, form, errorMessage } = this.props
		const { getFieldDecorator } = form

		if (isLoggedIn) {
			return <Redirect to='/' />
		}
		
		// if login failed (wrong username, password, ...)
		const failedAuth = isFailure ? (
			<div style={{ paddingTop: '10px' }}>
				<Alert message="Error" type="error"
					description={errorMessage}
				/>
			</div>
		) : (null)

		// if users token expired (401 in errorHandler)
		const expiredToken = tokenIsExpired ? (
			<div style={{ paddingTop: '10px' }}>
				<Alert message="Error" type="error"
					description="Your token expired. Please sign in again"
				/>
			</div>
		) : (null)

		return (
			<div>
				<Form onSubmit={this.handleSubmit} className="form-border">
					<Form.Item>
						{getFieldDecorator('email', {
							rules: [{
								required: true, message: 'Please input your E-mail!' },
							{
								type: 'email', message: 'The input is not valid E-mail!'
							}]
						})(
							<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
								id="email" placeholder="E-mail" onChange={this.handleChange} />
						)}
					</Form.Item>
					<Form.Item>
						{getFieldDecorator('password', {
							rules: [{ required: true, message: 'Please input your Password!' }]
						})(
							<Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
								type="password" id="password" placeholder="Password" onChange={this.handleChange} />
						)}
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit" >
							Log in
						</Button>

						<Link to={'/forgot-password'} style={{ float: 'right'}}>Forgot password</Link>
					</Form.Item>

					
				</Form>
				{failedAuth}
				{expiredToken}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		isLoggedIn: state.auth.isLoggedIn,
		isFailure: state.auth.isFailure,
		tokenIsExpired: state.auth.tokenIsExpired,
		errorMessage: state.auth.errorMessage
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		login: (credentials) => {
			dispatch(login(credentials))
		},
		errorClear: () => {
			dispatch({ type: actionType.auth.LOGIN_ERROR_CLEAR })
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
