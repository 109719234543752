import { message } from 'antd'

import { backendAxios } from '../utils/axiosWrapper'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'

/**
 * @param {Integer} pageValue wanted page
 * @param {Integer} pageSizeValue wanted number of items on one page
 * @param {} orderValue wanted order
 * @param {String} searchValue wanted search value
 */
export const getBrands = (pageValue = 1, pageSizeValue, orderValue, searchValue) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.brand.GET_BRANDS_REQUEST })
			const { actualPage, pageSize, order, search } = getState().brand.list

			const pageResult = pageValue || actualPage
			const pageSizeResult = pageSizeValue || pageSize
			const orderResult = orderValue === '' ? null : orderValue || order
			const searchResult = searchValue === '' ? null : searchValue || search

			const brands = await backendAxios.get('/brands', {
				params: {
					limit: pageSizeResult,
					page: pageResult,
					order: orderResult,
					search: searchResult
				}
			})

			// store listing data
			dispatch({ type: actionType.brand.GET_BRANDS_SUCCESS, data: { brands: brands.data.rows, totalBrands: brands.data.count } })

			// store listing params
			dispatch({ type: actionType.brand.SET_LISTING_PARAMS, data: { page:pageResult, pageSize: pageSizeResult, order: orderResult, search: searchResult }})
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.brand.GET_BRANDS_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const getBrand = (brandID, language) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.brand.GET_BRAND_REQUEST })
			
			const brand = await backendAxios.get(`/brands/${brandID}`, {
				headers: {
					'accept-language': language
				}
			})

			dispatch({ type: actionType.brand.GET_BRAND_SUCCESS, brand: brand.data })
			
			hideLoading()
			return brand.data
		} catch (err) {
			dispatch({ type: actionType.brand.GET_BRAND_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Loading')
			return err
		}
	}
}

export const deleteBrand = (brandID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Deleting in progress..', 0)
		try {
			dispatch({ type: actionType.brand.DELETE_BRAND_REQUEST })

			const { actualPage, pageSize, totalBrands } = getState().brand.list
			
			const brand = await backendAxios.delete(`/brands/${brandID}`)

			dispatch({ type: actionType.brand.DELETE_BRAND_SUCCESS, brand: brand.data })
			
			hideLoading()

			// after update is done, recalculate actual page and get all brands
			const pageCheck = ((totalBrands - 1) / pageSize) + 1
			const pageResult = actualPage >= pageCheck ? (actualPage - 1) : actualPage

			dispatch(getBrands((pageResult, undefined, undefined, undefined)))
		} catch (err) {
			dispatch({ type: actionType.brand.DELETE_BRAND_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Deleting')
		}
	}
}
