import { wrapper } from './AFormItem'

export const createSwitch = Component => (props) => {
    let defaultChecked
	
	if(props.input.value) {
		defaultChecked = props.input.value
	}
	
    const input = {
        ...props.input,
        checked: defaultChecked,
        onBlur: () => {}
	}
	
    return wrapper(Component, {...props, input })
}
