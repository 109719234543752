import { filter, map, find, mapValues, isArray, isEmpty, get } from 'lodash'
import { backendAxios, awsS3Axios } from '../utils/axiosWrapper'

/**
 * Map function which gets object id/s from its/their name/s
 * @param {String/Array} wantedItems name/list of names
 * @param {Array} allItemsList list which contains all objects
 * @returns {Integer/Array} itemId/itemIds
 */
export function getIdsFromNames(wantedItems, allItemsList) {
	if (Array.isArray(wantedItems)) {
		// if wantedItems is array, filter all wanted objects and return all ids from filtered list
		const filteredList = filter(allItemsList, (applicationObj) => {
			return wantedItems.includes(applicationObj.name)
		})
		const itemIds = map(filteredList, (item) => {
			return item.id
		})

		return itemIds
	} else {
		// if wantedItems is string, find object and return its id
		const foundItem = find(allItemsList, (itemObj) => {
			return wantedItems === itemObj.name
		})
		const itemId = foundItem ? foundItem.id : undefined

		return itemId
	}
}

/**
 * Upload file function
 * @param {Object/string} fileToUpload 
 * @return {string/undefined} access path to file
 */
export function uploadFile(fileToUpload) {
	return new Promise(async (resolve, reject) => {
		try {
			const { name, type, file } = fileToUpload
			const signUrlResult = await backendAxios.post('/fileUpload', { files: [{ name, type }] })

			const signedUrl = signUrlResult.data[0].signedUrl
			const accessUrl = signUrlResult.data[0].url
			
			await awsS3Axios.put(signedUrl, file, {headers: {"Content-Type": type}})

			return resolve(accessUrl)
		} catch (err) {
			return reject(err)
		}
	})
}

/**
 * Converts every objects value at first level from null to undefined
 * @param {Object} dataObject {name: null, title: 'title'}
 * @return {Object} {name: undefined, title: 'title}
 */
export function convertNullToUndefined(dataObject) {
	const resultObject = mapValues(dataObject, function(value) { return value === null ? undefined : value })
	return resultObject
}

function editProductVariants(values) {
	return map(values.variants, (variant) => {
		const materials = map(variant.materials, (material) => {
			const textures = map(material.textures, (texture) => {
				let resultImage
				if (texture.isImage) {
					const textureImage = get(texture, 'image')
					if (isArray(textureImage)){
						resultImage = get(textureImage, '[0].url')
					} else {
						resultImage = textureImage
					}
				} else {
					resultImage = null
				}
				return {
					type: texture.type,
					scale: texture.scale ? texture.scale : 1,
					isImage: texture.isImage ? true : false,
					color: !texture.isImage ? (get(texture,'color.hex') || get(texture,'color') || '#000000') : null,
					image: resultImage
				}
			})

			return {
				name: material.name,
				textures
			}
		})

		return {
			name: variant.name,
			default: variant.name === "Shared" && true,
			materials,
			thumbnail: get(variant, 'thumbnail[0].url'),
			modelUrl: get(variant, 'modelObj[0].url')
		}
	})
}

export const formatProductData = (values, allApplications, allCategories, allBrands, allProductLines ) => {
	// get applications ids
	const applicationIds = getIdsFromNames(values.applications, allApplications)

	// get category id
	const categoryId = getIdsFromNames(values.category, allCategories)

	// get brand id
	const brandId = getIdsFromNames(values.brand, allBrands)

	let productLineId = getIdsFromNames(values.productLine, allProductLines)

	if(isArray(productLineId) && isEmpty(productLineId)) {
		productLineId = null
	}

	
	const productThumbnail = get(values, 'productThumbnail')
	let resultImage
	if(isArray(productThumbnail)){
		resultImage = get(productThumbnail, '[0].url')
	} else {
		resultImage = productThumbnail
	}

	const gallery = map(values.gallery, (image) => {
		return image.url
	})

	// format variants
	const variants = editProductVariants(values)
	return {
		name: get(values, 'name'),
		description: get(values, 'description'),
		link: get(values, 'url'),
		image: resultImage,
		applicationIds: applicationIds,
		brandId,
		productLineId,
		categoryId,
		galleries: gallery,
		modelUrl: get(values, 'modelObj[0].url'),
		modelType: get(values, 'modelType'),
		modelSFBUrl: get(values, 'modelSFBUrl[0].url'),
		audioUrl: get(values, 'audioRecord[0].url') || null,
		variants,
		language: get(values, 'language')
	}
}
