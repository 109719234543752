import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SubmissionError, initialize } from 'redux-form'
import { map, get, has, find, isEmpty, uniqBy, isArray } from 'lodash'
import { Row, Col, message } from 'antd'
import { compose, bindActionCreators } from 'redux'

import ProductForm from './ProductForm'
import * as ProductActions from '../../actions/productActions'
import { getCategoriesByAppId } from '../../actions/categoryActions'
import { FORMS, ROLE_TYPE, LANGUAGE } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import { backendAxios } from '../../utils/axiosWrapper'
import errorHandler from '../../utils/errorHandler'
import { formatProductData } from '../../utils/helper'

class UpdateProduct extends Component {

	initForm = async(language) => {
		const { productActions, computedMatch, dispatch } = this.props
		const { productID } = computedMatch.params
		const product = await productActions.getProduct(productID, language)
		
		const assignedApps = map(product.applications, 'name')

		const brand = get(product, 'brand.name')
		const category = get(product, 'category.name')
		const productLine = get(product, 'productLine.name')

		const gallery = map(get(product, 'galleries'), (image) => {
			// get name from url
			const nameStartIndex = image.url.lastIndexOf('-')
			const nameWithoutExt = image.url.substring(nameStartIndex + 1)

			return {
				uid: image.id,
				url: image.url,
				name: nameWithoutExt
			}
		})

		// get name of model file from url
		let modelResult
		if (has(product, 'modelUrl')) {
			const { modelUrl } = product

			const nameStartIndexModel = modelUrl.lastIndexOf('-')
			const nameWithoutExtModel = modelUrl.substring(nameStartIndexModel + 1)
			modelResult = [{
				uid: 1,
				url: modelUrl,
				name: nameWithoutExtModel
			}]
		}
		
		// get name of modelSFB file from url
		let modelSFBResult
		if (has(product, 'modelSFBUrl')) {
			const { modelSFBUrl } = product

			const nameStartIndexSFB = modelSFBUrl.lastIndexOf('-')
			const nameWithoutExtSFB = modelSFBUrl.substring(nameStartIndexSFB + 1)
			modelSFBResult = [{
				uid: 1,
				url: modelSFBUrl,
				name: nameWithoutExtSFB
			}]
		}

		// get name of soundRecord file from url
		let audioRecordResult
		if (get(product, 'audioUrl')) {
			const { audioUrl } = product

			const nameStartIndexAudio = audioUrl.lastIndexOf('-')
			const nameWithoutExtAudio = audioUrl.substring(nameStartIndexAudio + 1)
			audioRecordResult = [{
				uid: 1,
				url: audioUrl,
				name: nameWithoutExtAudio
			}]
		}

		const defaultVariant = find(get(product, 'variants'), { default: true })
		const materials = map(get(defaultVariant, 'materials'), 'name')
		const variants = map(get(product, 'variants'), (variant) => {
			// get thumbnail of variant
			let thumbnail
			if (get(variant, 'thumbnail')) {
				thumbnail = [{ url: get(variant, 'thumbnail') }]
			}

			// get modelObj of variant
			let modelResult
			if (has(variant, 'modelUrl') && variant.modelUrl) {
				const { modelUrl } = variant

				const nameStartIndexModel = modelUrl.lastIndexOf('-')
				const nameWithoutExtModel = modelUrl.substring(nameStartIndexModel + 1)
				modelResult = [{
					uid: 1,
					url: modelUrl,
					name: nameWithoutExtModel
				}]
			}

			return {
				id: variant.id,
				name: variant.name,
				default: variant.default,
				thumbnail,
				modelObj: modelResult,
				materials: map(variant.materials, (material) => ({
					id: material.id,
					name: material.name,
					textures: map(material.textures, (texture) => ({
						id: texture.id,
						type: texture.type,
						scale: texture.scale,
						isImage: texture.isImage,
						color: texture.color,
						image: texture.image ? [{ url: get(texture, 'image') }] : null
					}))
				}))
			}
		})

		const initData = {
			...product,
			variants,
			url: product.link,
			applications: assignedApps,
			brand,
			productThumbnail: product.image,
			category,
			gallery,
			productLine,
			modelObj: modelResult,
			modelSFBUrl: modelSFBResult,
			audioRecord: audioRecordResult,
			materials: materials,
			language
		}
		dispatch(initialize(FORMS.PRODUCT, initData))
	}

	componentDidMount = () => {
		this.initForm(LANGUAGE.SK)
	}

	handleSubmit = async(values) => {
		const {
			dispatch,
			applications,
			brands,
			categories,
			productLines
		} = this.props
		
		const uniqueCategories = uniqBy(categories, 'name')
	
		if(isEmpty(values.category) && !isEmpty(uniqueCategories)) {
			throw new SubmissionError({
				category: 'Required'
			})
		} 

		if(isEmpty(values.applications)) {
			throw new SubmissionError({
				applications: 'Required'
			})
		} 

		if(isEmpty(values.brand)) {
			throw new SubmissionError({
				brand: 'Required'
			})
		} 

		const { productID } = this.props.computedMatch.params

		const hideLoading = message.loading('Updating in progress..', 0)
		try {
			const resultProduct = formatProductData(values, applications, categories, brands, productLines)

			let categoryId
			if(isArray(get(resultProduct, 'categoryId')) && isEmpty(get(resultProduct, 'categoryId'))) {
				categoryId = undefined
			} else {
				categoryId = get(resultProduct, 'categoryId')
			}
			
			const productData = {
				...resultProduct,
				categoryId
			}
			const { data } = await backendAxios.put(`/products/${productID}`, productData)
			
			hideLoading()
			message.success(`Product with ID: ${get(data, 'id')} was updated`, 3)
			return data
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Updating')
			return err
		}
	}

	render = () => {
		const { computedMatch } = this.props
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Update product</h1>
					<ProductForm computedMatch={computedMatch} onSubmit={this.handleSubmit} submitText="update" initForm={this.initForm} />
				</Col>
			</Row>
		)
				
	}
}

const mapStateToProps = (state) => {
	return {
		applications: state.application.list.data,
		brands: state.brand.list.data,
		categories: state.category.list.data,
		productLines: state.productLine.list.data
	}
}

const mapDispatchToProps = (dispatch) => ({
	productActions: bindActionCreators(ProductActions, dispatch),
	getCategoriesByAppId: (applications) => {
		dispatch(getCategoriesByAppId(applications))
	}
})


export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER, ROLE_TYPE.EDITOR]),
	connect(mapStateToProps, mapDispatchToProps)
)(UpdateProduct)
