import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'
import { withTranslation } from 'react-i18next'
import { map } from 'lodash'
import { Form, Input, Select, Switch, Button, Tabs } from 'antd'
import { reduxForm } from 'redux-form'

import UploadImage from '../../atoms/UploadImage'
import { createItem } from '../../atoms/formItems/AItem'
import { createSwitch } from '../../atoms/formItems/ASwitch'
import { createSelect } from '../../atoms/formItems/ASelect'
import  { required } from '../../enums/formValidations'
import { getBrands } from '../../actions/brandActions'
import { FORMS, LANGUAGES } from '../../enums/enums'

const { TabPane } = Tabs
const FormItem = Form.Item
const { Option } = Select

const AInput = createItem(Input)
const ASelect = createSelect(Select)
const ASwitch = createSwitch(Switch)
const AUpload = createItem(UploadImage)

class BannerForm extends React.Component {

	componentDidMount = () => {
		this.props.getBrands(1, 1000)
	}

	handleUrlOrBrandChange = (value) => {
		const { clearSubmitErrors } = this.props

		if(value) {
			clearSubmitErrors()
		} else {
			clearSubmitErrors()
		}
	}

	onChangeLanguage = (language) => {
		const { change, initForm, pristine, submit } = this.props
		if (pristine && initForm) {
			initForm(language)
		} else if (pristine) {
			change('language', language)
		} else if (!pristine && initForm){
			submit()
			initForm(language)
		} else {
			submit()
			change('language', language)
		}
	}

	render = () => {
		const { applications, brands, submitText, urlOrBrand, handleSubmit, pristine, reset, submitting, initialized, t, language } = this.props
		const applicationOptions = map(applications, (application) => {
			const { id, name } = application
			return <Option value={name} key={id}>{name}</Option>
		})

		const brandOptions = map(brands, (brand) => {
			const { id, name } = brand
			return <Option value={name} key={id}>{name}</Option>
		})
	
		const requiredField = { required: true, validate: [required] }
		const urlRequired = !urlOrBrand && requiredField
		const brandRequired = urlOrBrand && requiredField		
		
		return (<Tabs onChange={this.onChangeLanguage} activeKey={language}>
			{LANGUAGES.map(pane => (
				<TabPane tab={pane} key={pane}>
					<Form onSubmit={handleSubmit} >
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }}  {...requiredField} name="title" label={t('components:title')} placeholder={t('components:title')} component={AInput} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="subtitle" label={t('components:subtitle')} placeholder={t('components:subtitle')} component={AInput} />
			
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} {...requiredField} name="image" className="avatar-uploader" label={t('components:image')}
							width={300} height={150} showCropper={true} initialized={initialized} component={AUpload} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="urlOrBrand" label={t('components:urlOrBrand')} onChange={this.handleUrlOrBrandChange} component={ASwitch} />
			
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="url" label={t('components:url')} placeholder={t('components:url')} disabled={urlOrBrand}
							{ ...urlRequired } onChange={this.handleUrlChange} component={AInput} />

						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} showSearch name="brand" label={t('components:brand')} placeholder={t('components:brandPlaceholder')} disabled={!urlOrBrand}
							{ ...brandRequired } component={ASelect}>
								{brandOptions}
						</Field>
			
						<Field labelCol={{ span: 2 }} wrapperCol={{ span: 20 }} name="applications" label={t('components:applications')} placeholder={t('components:applicationsPlaceholder')}
							{...requiredField} mode="multiple" component={ASelect} >
								{applicationOptions}
						</Field>
						
						<FormItem>
							<Button type="primary" disabled={pristine || submitting} htmlType="submit" style={{ marginRight: "10px" }}>
								{t(`components:${submitText}`)}
							</Button>

							<Button disabled={pristine || submitting} onClick={reset}>
								{t('components:resetValues')}
							</Button>
						</FormItem>
					</Form>
				</TabPane>))}
			</Tabs>)
	}
}

const selector = formValueSelector(FORMS.BANNER)

const mapStateToProps = (state) => {
	return {
		applications: state.application.list.data,
		brands: state.brand.list.data,
		language: selector(state, 'language'),

		// state of urlOrBrand field
		urlOrBrand: selector(state, "urlOrBrand")
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getBrands: (page, pageSize) => {
			dispatch(getBrands(page, pageSize, 'name:ascend', ''))
		}
	}
}

const form = reduxForm({
	form: FORMS.BANNER,
	destroyOnUnmount: true
})(BannerForm)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation('components')
)(form)
