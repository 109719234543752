import actionType from '../enums/actions'

const initState = {
	list: {
		data: [],
		actualPage: 1,
		totalUsers: 0,
		pageSize: 10,
		order: null,
		search: null,
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {
			firstName: '',
			lastName: '', 
			email: '',
			role: ''
		},
		isLoading: false,
		isFailure: false
	}
}

const userReducer = (state = initState, action) => {
	switch (action.type) {
		case actionType.user.SET_LISTING_PARAMS: {
			return {
				...state,
				list: {
					...state.list,
					actualPage: action.data.page,
					pageSize: action.data.pageSize,
					order: action.data.order,
					search: action.data.search
				}
			}
		}
		case actionType.user.GET_USERS_REQUEST: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: true
				}
			
			}
		}
		case actionType.user.GET_USERS_SUCCESS: {
			return {
				...state,
				list: {
					...state.list,
					data: action.data.users,
					totalUsers: action.data.totalUsers,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.user.GET_USERS_ERROR: {
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.user.GET_USER_REQUEST: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true
				}
			}
		}
		case actionType.user.GET_USER_SUCCESS: {
			return {
				...state,
				detail: {
					...state.detail,
					data: action.user,
					isLoading: false,
					isFailure: false
				}
			}
		}
		case actionType.user.GET_USER_ERROR: {
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		}
		case actionType.user.REGISTER_USER_REQUEST: {
			return state
		}
		case actionType.user.REGISTER_USER_SUCCESS: {
			return state
		}
		case actionType.user.REGISTER_USER_ERROR: {
			return state
		}
		case actionType.user.CREATE_USER_REQUEST: {
			return state
		}
		case actionType.user.CREATE_USER_SUCCESS: {
			return state
		}
		case actionType.user.CREATE_BUSER_ERROR: {
			return state
		}
		case actionType.user.UPDATE_USER_REQUEST: {
			return state
		}
		case actionType.user.UPDATE_USER_SUCCESS: {
			return state
		}
		case actionType.user.UPDATE_USER_ERROR: {
			return state
		}
		case actionType.user.DELETE_USER_REQUEST: {
			return state
		}
		case actionType.user.DELETE_USER_SUCCESS: {
			return state
		}
		case actionType.user.DELETE_USER_ERROR: {
			return state
		}
		case actionType.RESET_STATE: {
			return {
				...initState
			}
		}
		default:
			return state
	}
}

export default userReducer
