import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, message } from 'antd'
import { compose } from 'redux'
import { reset, initialize } from 'redux-form'
import { get} from 'lodash'
import { withRouter } from 'react-router-dom'

import CategoryForm from './CategoryForm'
import { ROLE_TYPE, LANGUAGE, FORMS } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'
import { getIdsFromNames } from '../../utils/helper'
import { backendAxios } from '../../utils/axiosWrapper'
import errorHandler from '../../utils/errorHandler'

class CreateCategory extends Component {

	handleSubmit = async (values) => {
		const { allApplications, dispatch, history } = this.props
		const hideLoading = message.loading('Creating in progress..', 0)

		try {
			const { name, image, applications } = values
			// get applications ids
			const applicationIds = getIdsFromNames(applications, allApplications)

			const resultImage = image && image[0].url

			const postData = {
				name, image: resultImage, applicationIds, language: values.language
			}
			const { data } = await backendAxios.post('/categories', postData)
			
			hideLoading()
			message.success(`Category with ID: ${get(data, 'id')} was created`, 3)
			dispatch(reset(FORMS.CATEGORY))
			history.push(`/categories/update/${get(data, 'id')}`)
			return
		} catch (err) {
			errorHandler(err, dispatch, hideLoading, 'Creating')
			return err
		}
	}

	componentDidMount = () => {
		const { dispatch } = this.props

		dispatch(reset(FORMS.CATEGORY))
		dispatch(initialize(FORMS.CATEGORY, {
			language: LANGUAGE.SK
		}))
	}

	render() {
		return (
			<Row type="flex" justify="center">
				<Col lg={{ span: 24 }}>
					<h1 style={{ textAlign: "center" }}>Create category</h1>
					<CategoryForm onSubmit={this.handleSubmit} submitText="create" />
				</Col>
			</Row>
		)
	}
}

const mapStateToProps = (state) => ({
	allApplications: state.application.list.data
})

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN]),
	connect(mapStateToProps, mapDispatchToProps)
)(withRouter(CreateCategory))
