import { message } from 'antd'
import { reset } from 'redux-form'

import { backendAxios } from '../utils/axiosWrapper'
import errorHandler from '../utils/errorHandler'
import actionType from '../enums/actions'
import { convertNullToUndefined } from '../utils/helper'
import { FORMS } from '../enums/enums'

/**
 * @param {Integer} pageValue wanted page
 * @param {Integer} pageSizeValue wanted number of items on one page
 * @param {} orderValue wanted order
 * @param {String} searchValue wanted search value
 */
export const getUsers = (pageValue = 1, pageSizeValue, orderValue, searchValue) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.user.GET_USERS_REQUEST })
			const { actualPage, pageSize, order, search } = getState().user.list

			const pageResult = pageValue || actualPage
			const pageSizeResult = pageSizeValue || pageSize
			const orderResult = orderValue === '' ? null : orderValue || order
			const searchResult = searchValue === '' ? null : searchValue || search

			const users = await backendAxios.get('/users', {
				params: {
					limit: pageSizeResult,
					page: pageResult,
					order: orderResult,
					search: searchResult
				}
			})

			// store listing data
			dispatch({ type: actionType.user.GET_USERS_SUCCESS, data: { users: users.data.rows, totalUsers: users.data.count } })

			// store listing params
			dispatch({ type: actionType.user.SET_LISTING_PARAMS, data: { page:pageResult, pageSize: pageSizeResult, order: orderResult, search: searchResult }})
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.user.GET_USERS_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

export const getUser = (userID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Loading in progress..', 0)
		try {
			dispatch({ type: actionType.user.GET_USER_REQUEST })
			
			const user = await backendAxios.get(`/users/${userID}`)

			dispatch({ type: actionType.user.GET_USER_SUCCESS, user: user.data })
			
			hideLoading()
		} catch (err) {
			dispatch({ type: actionType.user.GET_USER_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Loading')
		}
	}
}

/**
 * Register new user by sending registration email
 * @param {String} email 
 */
export const registerUser = (email) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Registering in progress..', 0)

		try {
			dispatch({ type: actionType.user.REGISTER_USER_REQUEST })
			
			await backendAxios.post('/users', { email })

			dispatch({ type: actionType.user.REGISTER_USER_SUCCESS })
			
			hideLoading()
			message.success('Registering successfull', 3)
			dispatch(reset(FORMS.CREATE_USER))
		} catch (err) {
			dispatch({ type: actionType.user.REGISTER_USER_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Registering')
		}
	}
}

/**
 * Create new user
 * @param {Object} param0 
 */
export const createUser = ({ firstName, lastName, password, confirmPassword }) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Registering in progress..', 0)

		try {
			dispatch({ type: actionType.user.CREATE_USER_REQUEST })
			
			const user = {
				firstName, lastName, password, confirmPassword
			}

			await backendAxios.put('/register', user)

			dispatch({ type: actionType.user.CREATE_USER_SUCCESS })
			
			hideLoading()
			message.success('Registering successfull', 3)
		} catch (err) {
			dispatch({ type: actionType.user.CREATE_USER_ERROR})
			errorHandler(err, dispatch, hideLoading, 'Registering')
		}
	}
}

export const updateUser = (userID, data) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Updating in progress..', 0)
		try {
			dispatch({ type: actionType.user.UPDATE_USER_REQUEST })

			// modified data for request (null->undefined)
			const payload = convertNullToUndefined(data)
			
			// final data for request
			const user = {
				...payload
			}
			
			await backendAxios.put(`/users/${userID}`, user)

			dispatch({ type: actionType.user.UPDATE_USER_SUCCESS })
			
			hideLoading()
			message.success('User was updated', 3)
		} catch (err) {
			dispatch({ type: actionType.user.UPDATE_USER_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Updating')
		}
	}
}

export const deleteUser = (userID) => {
	return async (dispatch, getState) => {
		const hideLoading = message.loading('Deleting in progress..', 0)
		try {
			dispatch({ type: actionType.user.DELETE_USER_REQUEST })

			const { actualPage, pageSize, totalUsers } = getState().user.list
			
			const user = await backendAxios.delete(`/users/${userID}`)

			dispatch({ type: actionType.user.DELETE_USER_SUCCESS, user: user.data })
			
			hideLoading()

			// after update is done, recalculate actual page and get all users
			const pageCheck = ((totalUsers - 1) / pageSize) + 1
			const pageResult = actualPage >= pageCheck ? (actualPage - 1) : actualPage

			dispatch(getUsers((pageResult, undefined, undefined, undefined)))
		} catch (err) {
			dispatch({ type: actionType.user.DELETE_USER_ERROR })
			errorHandler(err, dispatch, hideLoading, 'Deleting')
		}
	}
}
