import React from 'react'
import Croppie from 'croppie'
import { map, filter, get, isArray, cloneDeep } from 'lodash'
import { Upload, Modal, Icon, Button } from 'antd'
import { uploadFile } from '../utils/helper'
import "croppie/croppie.css"

class MyUpload extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			uploadedFiles: [],
			cropModalVisible: false
		}

		this.croppieElement = React.createRef()
		this.croppieInstance = null
	}
	
	handleBeforeUpload = (file, fileList) => {
		const { showCropper } = this.props

		if(showCropper) {
			this.setState({
				uploadedFiles: fileList,
				cropModalVisible: true
			})
		} else {
			// upload files
			this.uploadFiles(fileList)
		}

		return false
	}

	uploadFiles = async (fileList) => {
		const { value, onChange, multiple } = this.props

		const uploadFileList = map(fileList, (file) => {
			const { name, type } = file
			
			return uploadFile({ name, type, file })
		})
		const uploadedFileList = await Promise.all(uploadFileList)

		// create file object ({ uid, name, type, url })
		const uploadedFileObjectList = map(fileList, (file, index) => {
			const { uid, name, type } = file

			return { uid, name, type, url: uploadedFileList[index] }
		})

		let joinedUploadList
		if(multiple) {
			joinedUploadList = [...value, ...uploadedFileObjectList]
		} else {
			joinedUploadList = uploadedFileObjectList
		}
		
		// change redux value
		onChange(joinedUploadList)
	}

	handleCropModalOk = async (e) => {
		const { uploadedFiles } = this.state
		const { onChange } = this.props

		const croppedImage = await this.getCroppieResult()
		const { uid, name } = uploadedFiles[0]
		const { type } = croppedImage

		// name without extension
		const extensionStartIndex = name.lastIndexOf('.')
		const nameWithoutExt = name.substring(0, extensionStartIndex)
		const nameWithPNGExt = `${nameWithoutExt}.png`

		// upload file
		const uploadedFileUrl = await uploadFile({ name: nameWithPNGExt, type, file: croppedImage })

		const uploadedFileObjectList = [{ uid, name, type, url: uploadedFileUrl }]

		// change redux value
		onChange(uploadedFileObjectList)

		this.setState({
			cropModalVisible: false
		})
	}
	
	handleCropModalCancel = (e) => {
		this.setState({
			cropModalVisible: false
		})
	}

	handleRemoveImage = (removedFile) => {
		const { onChange, value } = this.props

		// const fileIndex = indexOf(value, file)
		const newFileList = filter(value, (file, index) => get(file, 'uid') !== get(removedFile, 'uid'))
		onChange(newFileList)
    }

	setupCroppie = (width, height) => {
		const { uploadedFiles } = this.state

		const croppieElement = this.croppieElement.current

		const boundaryWidth = window.innerWidth < 786 ? 300 : 400
		const boundaryHeight = 300

		if (!this.croppieInstance) {
			this.croppieInstance = new Croppie(croppieElement, {
				enforceBoundary: false,
				viewport: {
					width,
					height,
					type: 'square'
				},
				boundary: {
					width: boundaryWidth,
					height: boundaryHeight
				},
				showZoomer: true
			})
		}

		const imageUrl = URL.createObjectURL(uploadedFiles[0])
		this.croppieInstance.bind({
			url: imageUrl
		})
	}

	getCroppieResult = () => {
		const croppedImage = this.croppieInstance.result({
			type: 'blob',
			size: 'original',
			format: 'png',
			circle: false
		})

		return croppedImage
	}

	render = () => {
		const { cropModalVisible } = this.state
		const { value, width, height, multiple, initialized } = this.props

		// if width a height are not in props, set default
		const newWidth = width || 50
		const newHeight = height || 50

		let image
		if (!multiple) {
			if(initialized) {
				// if image has changed in update form
				if(isArray(value)) {
					image = value && <img src={value[0].url} alt={value[0].name} style={{ width: newWidth, height: newHeight }} />
				} else {
					// get name from url
					const nameStartIndex = value.lastIndexOf('-')
					const nameWithoutExt = value.substring(nameStartIndex)

					image = value && <img src={value} alt={nameWithoutExt} style={{ width: newWidth, height: newHeight }} />
				}
			} else {
				image = value && <img src={value[0].url} alt={value[0].name} style={{ width: newWidth, height: newHeight }} />
			}
		}

		const listType = multiple ? 'picture' : 'picture-card'
		const showUploadList = multiple ? true : false

		const uploadButtonSingle = (
			<div>
				<Icon type="plus" />
        		<div className="ant-upload-text">Upload</div>
			</div>
		)

		const uploadButtonMultiple = (
			<Button>
				<Icon type="upload" /> Select image
			</Button>
		)

		// create croppie, if modal is visible
		cropModalVisible && this.setupCroppie(width, height)

		return (
			<div>
				<Upload listType={listType} fileList={cloneDeep(value)} showUploadList={showUploadList} beforeUpload={this.handleBeforeUpload}
					onRemove={this.handleRemoveImage} multiple={multiple} className="avatar-uploader">
					{ value && !multiple && image }
					{ !value && !multiple && uploadButtonSingle}
					{ multiple && uploadButtonMultiple}
				</Upload>
				<Modal title="Crop image" visible={cropModalVisible} forceRender={true} onOk={this.handleCropModalOk} onCancel={this.handleCropModalCancel}>
					<div id="croppie" ref={this.croppieElement}></div>
				</Modal>
			</div>
		)
	}
}

export default MyUpload
