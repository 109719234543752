export const MODEL_TYPES_OBJECT = {
	FLOOR: 'FLOOR',
	WALL: 'WALL',
	CEILING: 'CEILING'
}

export const MODEL_TYPES_ARRAY = Object.values(MODEL_TYPES_OBJECT)

export const TEXTURE_TYPE = {
	DIFFUSE: "DIFFUSE",
	METALNESS: "METALNESS",
	ROUGHNESS: "ROUGHNESS",
	OCCLUSION: "OCCLUSION",
	NORMAL: "NORMAL",
	TRANSPARENT: "TRANSPARENT",
	EMISSION: "EMISSION",
	ILLUMINATION: "ILLUMINATION"
}

export const TEXTURE_TYPES = Object.values(TEXTURE_TYPE)

export const ROLE_TYPE = {
	ADMIN: 'ADMIN',
	MANAGER: 'MANAGER',
	EDITOR: 'EDITOR'
}

export const ROLE_TYPES = Object.values(ROLE_TYPE)

export const FORMS = {
	BANNER: 'BANNER',
	PRODUCT: 'PRODUCT',
	PRODUCT_LINE: 'PRODUCT_LINE',
	BRAND: 'BRAND',
	CATEGORY: 'CATEGORY',

	CREATE_USER: 'createUser',
	REGISTER: 'register'
}

export const LANGUAGE = {
	SK: 'sk',
	EN: 'en',
	SL: 'sl',
	HR: 'hr',
	SR: 'sr',
	BS: 'bs'
}

export const LANGUAGES = Object.values(LANGUAGE)
