import { wrapper } from './AFormItem'

export const createSelect = Component => (props) => {
    let value = props.input.value
	
	if(props.input.value === "") {
		if(props.mode === "multiple" || props.mode === "tags")
		value = []
	}
	
    const input = {
        ...props.input,
        value: value,
        onBlur: () => {}
	}
	
    return wrapper(Component, {...props, input })
}
