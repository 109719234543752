import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { camelCase, get } from 'lodash'
import { Table, Button, Popconfirm, Divider, Row, Col, Icon } from 'antd'
import { compose } from 'redux'

import SearchBrand from './SearchBrand'
import { getBrands, deleteBrand } from '../../actions/brandActions'
import { withPermissions } from '../../utils/permissionsHoc'
import { ROLE_TYPE } from '../../enums/enums'

class BrandList extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pagination: { position: 'none' },
			size: 'middle',
			showHeader: true
		}
	}

	columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			defaultSortOrder: 'ascend',
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Logo',
			dataIndex: 'logo',
			key: 'logo',
			render: (text, brand) => {
				if(get(brand, 'logo')){
					return <img alt={get(brand, 'name')} style={{display: 'block', maxWidth: '180px', maxHeight: '70px', width: 'auto', height: 'auto'}} src={get(brand, 'logo')}/>
				} else {
					return ''
				}
			}
		}, {
			title: 'Promoted',
			dataIndex: 'isPromoted',
			key: 'isPromoted',
			sorter: true,
			sortDirections: ['ascend', 'descend'],
			render: (text, brand) => {
				return  brand.isPromoted ? <Icon type="check" /> : <Icon type="close" />
			}
		}, {
			title: 'Languages',
			dataIndex: 'languages',
			key: 'laguages',
			sorter: false
		}, {
			title: 'Action',
			key: 'action',
			render: (text, brand) => {
				return this.props.totalBrands >= 1
					? (
						<span>
							<Link to={'/brands/update/' + brand.id}>Update</Link>
							<Divider type="vertical" />
							<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(brand.id)}>
								<Link to={'#'}>Delete</Link>
							</Popconfirm>
						</span>
					) : null
			}
		}
	]

	componentDidMount() {
		this.props.getBrands(undefined, undefined, 'name:ascend', '')
	}

	handleDelete = (brandID) => {
		this.props.deleteBrand(brandID)
	}

	handleSearch = (value) => {
		this.props.getBrands(undefined, undefined, undefined, value.search)
	}

	handlePageSizeChange = (current, pageSize) => {
		this.props.getBrands(current, pageSize, undefined, undefined)
	}

	handleChange = (pagination, filters, sorter) => {
		// get correct format of sort parameter
		// exp. brandName:ascend
		const orderValue = `${camelCase(sorter.field)}:${sorter.order}`

		if (sorter.field) {
			this.props.getBrands(pagination.current, pagination.pageSize, orderValue, undefined)
		} else {
			this.props.getBrands(pagination.current, pagination.pageSize, '', undefined)
		}
	}
  
	render = () => {
		const { brands, actualPage, totalBrands, isLoading } = this.props

		const columns = this.columns
		const showTotalFunction = (total, range) => {
			return `${range[0]}-${range[1]} of ${total} items`
		}
		const data = brands || null

		return (
			<div>
				<Row type="flex" align="middle" style={{ marginBottom: '10px' }}>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							<Link to='/brands/create'>Create brand</Link>
						</Button>
					</Col>
					<Col span={8} offset={8}>
						<SearchBrand/>
					</Col>
				</Row>

				<Row style={{ marginBottom: '10px' }}>
					<Table {...this.state} columns={columns} pagination={{
						showTotal: showTotalFunction,
						current: actualPage,
						total: totalBrands,
						showSizeChanger: true,
						onShowSizeChange: this.handlePageSizeChange
					}} dataSource={data} rowKey="id" loading={isLoading} onChange={this.handleChange} />
				</Row>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		brands: state.brand.list.data,
		isLoading: state.brand.list.isLoading,
		actualPage: state.brand.list.actualPage,
		totalBrands: state.brand.list.totalBrands,
		pageSize: state.brand.list.pageSize,
		pageSizes: state.brand.list.pageSizes
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getBrands: (pageValue, pageSizeValue, orderValue, searchValue) => {
			dispatch(getBrands(pageValue, pageSizeValue, orderValue, searchValue))
		},
		deleteBrand: (brandID) => {
			dispatch(deleteBrand(brandID))
		}
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN, ROLE_TYPE.MANAGER]),
	connect(mapStateToProps, mapDispatchToProps)
)(BrandList)
