import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { camelCase } from 'lodash'
import { Table, Button, Popconfirm, Divider, Row, Col } from 'antd'
import { compose } from 'redux'

import SearchUser from './SearchUser'
import { getUsers, deleteUser } from '../../actions/userActions'
import { ROLE_TYPE } from '../../enums/enums'
import { withPermissions } from '../../utils/permissionsHoc'

class UserList extends React.Component {

	columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'First name',
			dataIndex: 'firstName',
			key: 'firstName',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Last name',
			dataIndex: 'lastName',
			key: 'lastName',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Role',
			dataIndex: 'role',
			key: 'role',
			sorter: true,
			sortDirections: ['ascend', 'descend']
		}, {
			title: 'Actions',
			key: 'actions',
			render: (text, user) => {
				return this.props.totalUsers >= 1
					? (
						<span>
							<Link to={'/administration/users/update/' + user.id}>Update</Link>
							<Divider type="vertical" />
							<Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(user.id)}>
								<Link to={'#'}>Delete</Link>
							</Popconfirm>
						</span>
					) : null
			}
		}
	]

	componentDidMount() {
		this.props.getUsers(undefined, undefined, '', '')
	}

	handleDelete = (userID) => {
		this.props.deleteUser(userID)
	}

	handleSearch = (value) => {
		this.props.getUsers(undefined, undefined, undefined, value.search)
	}
	
	handlePageChange = (page) => {
		this.props.getUsers(page, undefined, undefined, undefined)
	}

	handlePageSizeChange = (current, pageSize) => {
		this.props.getUsers(current, pageSize, undefined, undefined)
	}

	handleChange = (pagination, filters, sorter) => {
		// get correct format of sort parameter
		// exp. email:ascend
		const orderValue = `${camelCase(sorter.field)}:${sorter.order}`

		if (sorter.field) {
			this.props.getUsers(pagination.current, pagination.pageSize, orderValue, undefined)
		} else {
			this.props.getUsers(pagination.current, pagination.pageSize, '', undefined)
		}
	}
  
	render() {
		const columns = this.columns
		const showTotalFunction = (total, range) => {
			return `${range[0]}-${range[1]} of ${total} items`
		}

		const { users, actualPage, totalUsers, isLoading } = this.props
		const data = users || null

		return (
			<div>
				<Row type="flex" align="middle" style={{ marginBottom: '10px' }}>
					<Col span={8}>
						<Button type="primary" htmlType="submit">
							<Link to='/administration/users/create'>Create user</Link>
						</Button>
					</Col>
					<Col span={8} offset={8}>
						<SearchUser/>
					</Col>
				</Row>

				<Row style={{ marginBottom: '10px' }}>
					<Table {...this.state} pagination={{
						showTotal: showTotalFunction,
						current: actualPage,
						total: totalUsers,
						showSizeChanger: true,
						onShowSizeChange: this.handlePageSizeChange
					}} columns={columns} dataSource={data} rowKey="id" loading={isLoading} onChange={this.handleChange} />
				</Row>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		users: state.user.list.data,
		isLoading: state.user.list.isLoading,
		actualPage: state.user.list.actualPage,
		totalUsers: state.user.list.totalUsers,
		pageSize: state.user.list.pageSize,
		pageSizes: state.user.list.pageSizes
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUsers: (pageValue, pageSizeValue, orderValue, searchValue) => {
			dispatch(getUsers(pageValue, pageSizeValue, orderValue, searchValue))
		},
		deleteUser: (userID) => {
			dispatch(deleteUser(userID))
		}
	}
}

export default compose(
	withPermissions([ROLE_TYPE.ADMIN]),
	connect(mapStateToProps, mapDispatchToProps)
)(UserList)
